import React, { useContext, useState } from "react";
import { UserContext } from "../context/userContext";
import { InputText } from 'primereact/inputtext';
import { Button } from 'primereact/button';
import { Message } from 'primereact/message';
import { Card } from 'primereact/card';
import { Password } from 'primereact/password';

const Public = (props) => {
    const { signIn, confirmCode, resendConfirmationCode } = useContext(UserContext);

    const [validation, setValidation] = useState({
        show: false,
        severity: "", // warn  info success error
        text: ""
    });
    const [confirm, setConfirm] = useState(false);

    const [inputs, setInputs] = useState({
        email: '',
        password: '',
        confirmCode: ''
    })
    const submit = async () => {
        console.log('submit')
        try {
            const sign = await signIn({
                email: inputs.email,
                password: inputs.password
            })
            console.log('SignIn:', sign)
            if (!!!sign.status) { throw sign.error }
            setValidation({
                severity: "",
                text: "",
                show: false
            });
        } catch (error) {
            console.log('error:', error)
            if (error === "User is not confirmed.") {
                setValidation({
                    severity: "info",
                    text: "merci de renseigner le code de confirmation envoyé par email",
                    show: true
                });
                setConfirm(true)
            } else {
                setValidation({
                    severity: "error",
                    text: "email ou mot de passe incorrect",
                    show: true
                });

            }
        }

    }

    const sendconfirmCode = async () => {
        await confirmCode({
            email: inputs.email,
            confirmCode: inputs.confirmCode
        }).then(res => {
            console.log('res:', res)
            if (res.status) {
                setConfirm(false)
                submit()
            } else {
                throw res.error
            }
        }).catch(err => {
            console.log('err:', err)
            setConfirm(false)
            setValidation({
                severity: "error",
                text: err,
                show: true
            });
        })
    }

    const resendCode = async () => {
        await resendConfirmationCode({
            email: inputs.email
        })
            .then(res => {
                setValidation({
                    severity: "success",
                    text: "code envoyé par mail",
                    show: true
                });
            })
            .catch(err => {
                setValidation({
                    severity: "error",
                    text: "erreur envoi du code",
                    show: true
                });
            })
    }

    return (
        <div className="bg-bluegray-900 flex justify-content-center align-items-center h-screen w-screen p-2">

            <Card className="w-auto h-auto flex align-items-center">
                {validation.show &&
                    <Message
                        severity={validation.severity}
                        text={validation.text}
                    />
                }
                {!confirm ?
                    <>
                        <div className="flex-column flex m-2">
                            <label htmlFor="email">Email</label>
                            <InputText
                                id="email" value={inputs.email} onChange={(e) => setInputs({ ...inputs, email: e.target.value })} />
                        </div>
                        <div className="flex-column flex m-2">
                            <label htmlFor="password">Mot de passe</label>
                            <Password
                                id="password" feedback={false} value={inputs.password} onChange={(e) => setInputs({ ...inputs, password: e.target.value })} />
                        </div>
                        <div className="flex justify-content-center m-2">
                            <Button
                                className="p-button-success"
                                onClick={() => submit()}
                                label={`Connexion`}
                            />
                        </div>
                    </>
                    :
                    <>
                        <div className="flex-column flex m-2">
                            <label htmlFor="username">code de confirmation</label>
                            <InputText id="username" value={inputs.confirmCode} onChange={(e) => setInputs({ ...inputs, confirmCode: e.target.value })} />
                        </div>
                        <div className="flex flex-wrap justify-content-around">
                            <div className="flex justify-content-center m-2">
                                <Button
                                    className="p-button-success"
                                    onClick={() => sendconfirmCode()}
                                    label={`Confirmer`}
                                />
                            </div>
                            <div className="flex justify-content-center m-2">
                                <Button
                                    className="p-button-info"
                                    onClick={() => resendCode()}
                                    label={`Renvoyer le code`}
                                />
                            </div>
                        </div>
                    </>
                }
            </Card>
        </div>

    )

}

export default Public; 