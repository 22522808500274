
export const infosSection = {

    null: {
        label: "Infos Section",
        infos: "Infos Section"
    },

    0: {
        label: "annul14js",
        infos: `
        Cette section est destinée aux remboursements sous 14 jours.\n
        La procédure rembourse completement l'abonnement en cours du client.\n
        Les fichiers Reporting, Suivi des acceptations, Test New Vip et LeadsAlphabody seront modifiés.\n
        L'espace Kajabi sera retiré, ainsi que l'envoi de mail
        `
    },

    1: {
        label: "arretNow",
        infos: `
        Cette section permet d'arreter un abonnement et d'en rembourser une partie (facultatif).\n
        Lancer cette procédure arretera l'abonnement du client\n
        et modifira les fichiers Reporting, Suivi des Accepations et Test New Vip et arretera l'espace Kajabi.
        `
    },

    2: {
        label: "arretDiff",
        infos: `
        a cette date les fichiers et la base de donées seront modifiés\n
        ses acces Kajabi et l'envoi de mail arretés\n
        Un mail est envoyé sur contact@alphabody.fr le jour de l'execution de l'arret
        `
    },
    3: {
        label: "VipToForm",
        infos: `
        Passage d'un client Vip vers Formation\n
        Modification des fichiers sheet\n
        Application du changement de commish si moins de 15js\n
        Modification du produit dans stripe et en BDD
        `
    },
    4: {
        label: "msgCoach",
        infos: `
        Permet l'envoi d'un message sur le telegram du coach\n
    `
    },
    5: {
        label: "renewal",
        infos: `
        raccourci vers la page permettant de renouveller un client\n
        avec ses infos préremplies
    `
    },
    6: {
        label: "invoices",
        infos: `
    Affichage et téléchargement \nde toutes les factures quaderno trouvées avec cet email\n
    `
    },
    7: {
        label: "stripeList",
        infos: `
    Affichage de tous les cient stripe ayant cet email avec les abonnements\n
    Les sessions du portail sont temporaires. Les nouvelles sessions de portail expirent après une période de cinq minutes. Si un client l'utilise pendant cette période, la session expire dans l'heure qui suit l'activité la plus récente. Chaque session de portail peut durer jusqu'à deux heures avant d'expirer.
    `
    },
    8: {
        label: "almaList",
        infos: `
    Affichage de tous les payment alma ayant cet email \n
    `
    },

}