import axios from 'axios';

export const sendCoachTelegram = async (props) => {
    console.log('props Telegram:', props)

    const url = `https://api-rest.alphabody.fr:2053/telegram/text`

    const telegram = await axios({
        method: 'post',
        url: url,

        data: {
            "recipient": "coach",
            "id": props.coach_id,
            "message": props.text
        }
    })

    if (telegram) {
        return telegram.data
    } else {
        return null
    }
}