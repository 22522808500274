import React, { useState } from 'react';
import { Calendar } from 'primereact/calendar';
import { addLocale } from 'primereact/api';
import { SendArretDiff } from '../../callApi/sendArretDiff';
import { cancelSubsEndPeriod } from '../../callApi/scheduleSubsStripe'
import { modifSheet } from '../../callApi/sheets'
import { Checkbox } from 'primereact/checkbox';
import { ProgressSpinner } from 'primereact/progressspinner';
import { Tag } from 'primereact/tag';

// Composant permettant de lancer les arret diférés
const ArretsDiff = (props) => {
    console.log('props:', props)

    //creation de la traduction pour le calendrier
    addLocale('fr', {
        firstDayOfWeek: 1,
        dayNames: ['dimanche', 'lundi', 'mardi', 'mercredi', 'jeudi', 'vendredi', 'samedi'],
        dayNamesShort: ['dim', 'lun', 'mar', 'mer', 'jeu', 'vend', 'sam'],
        dayNamesMin: ['Dimanche', 'Lundi', 'Mardi', 'Mercredi', 'Jeudi', 'Vendredi', 'Samedi'],
        monthNames: ['janvier', 'fevrier', 'mars', 'avril', 'mai', 'juin', 'juillet', 'aout', 'septembre', 'octobre', 'novembre', 'decembre'],
        monthNamesShort: ['Ja', 'Fe', 'Ma', 'Av', 'Ma', 'Ju', 'Jui', 'Ao', 'Se', 'Oc', 'No', 'Dec'],
        today: 'Jour',
        clear: 'Effacer'
    });

    //transformation de la date au bon format et envoi dans le state du composant apres verif si supperieure a aujourd'hui
    const changeDate = (value) => {

        const selectDate = new Date(value)
        const today = new Date()

        if (today < selectDate) {
            setError({ success: null, message: '' })
            setDate(selectDate.toLocaleDateString(`fr-FR`, { year: 'numeric', month: '2-digit', day: '2-digit' }))
            setbtn(true)
        } else {
            setDate(null)
            setError({ success: false, message: `merci de renseigner une date superieure a aujourd'hui` })
            setbtn(false)
        }
    }

    let [date, setDate] = useState()
    let [btn, setbtn] = useState(false)
    let [spinner, setSpinner] = useState(false)
    let [error, setError] = useState({ success: null, message: '' })
    let [sendMsg, setsendMsg] = useState(true)

    //appel de l'enregistrement au click
    const click = async () => {
        setbtn(false)
        setSpinner(true)

        //planification de l'arret diff stripe
        let endSchedule
        if (props.customer.sale.processor.toLowerCase() === "stripe") {
            endSchedule = await cancelSubsEndPeriod({
                subs: props.subs,
                action: 'cancel'
            })
        } else { //cas alma ou rib
            endSchedule = {
                status: "OK"
            }
        }
        //enregistrement en dynamo
        const result = await SendArretDiff({
            date: date,
            props: props,
            sendMsg: sendMsg
        })
        //ecriture de la date dans test new vip si vip
        let resultModifTnv = null
        if (props.customer.product_id.id === '3' || props.customer.product_id.id === '4') {
            const modifTnv = await modifSheet({
                "doc": "TestNewVip",
                "sheet": "Arrivé des VIPs",
                "row": props.rowTnv.row,
                "modifs": {
                    "K": date
                },
                "notes": {
                    "K": "Arrêt différé programmé"
                },
                "delRow": null
            })
            console.log('modifTnv:', modifTnv)
            if (modifTnv.statusCode === 200) {
                resultModifTnv = "OK"
            } else {
                resultModifTnv = "ECHEC"
            }
        }

        //analyse du resultat
        console.log('result:', result)
        console.log('endSchedule:', endSchedule)
        if (result.status === "OK" && endSchedule.status === "OK" && resultModifTnv === "OK") {
            setError({ success: true, message: `` })
            setSpinner(false)
        } else {
            setError({ success: false, message: `Erreur sur l'enregistrement de l'arret différé` })
            setbtn(true)
            setSpinner(false)
        }
    }

    return (
        <div >
            <div className="m-2">
                <Calendar dateFormat="dd/mm/yy" id="icon" locale="fr" value={date} onChange={(e) => changeDate(e.value)} showIcon />
            </div>
            {!!date && btn &&
                <div className="m-2">
                    <button onClick={click}>Arret le {date}</button>
                </div>
            }
            <div>
                <Checkbox inputId="msg" onChange={e => setsendMsg(e.checked)} checked={sendMsg}></Checkbox>
                <label htmlFor="msg" className="p-checkbox-label m-2">Prevenir le coach</label>
            </div >
            {
                spinner &&
                <ProgressSpinner className="p-1 text-sm" style={{ width: '40px', height: '40px' }} strokeWidth="4" animationDuration="1.5s" />
            }

            {
                error.success === false &&
                <div className="m-2">
                    <Tag className="text-xl m-1" icon="text-xl pi pi-exclamation-triangle" severity="warning" value={error.message}></Tag>
                </div>
            }
            {
                error.success === true &&
                <div className="m-2">
                    <Tag className="text-xl m-1" icon="text-xl pi pi-check" severity="success" value={`Arret enregistré avec succes le ${date}`}></Tag>
                </div>
            }

        </div >

    )
}

export default ArretsDiff;