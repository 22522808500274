import React, { useState } from 'react';
import { Button } from 'primereact/button';

// Composant permettant de lancer les arret diférés
const Invoices = (props) => {

    const click = () => {
        window.open(`https://www.paiement.alphabody.fr/renewal/?email=${props.customer.email}`)
    }

    return (
        <div >
            <Button
                className="m-1 p-button-success"
                onClick={(e) => click()}
                label='Page de paiement renouvellement'
            />
        </div >

    )
}

export default Invoices;