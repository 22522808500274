import React, { useEffect } from 'react';

import { useNavigate } from "react-router-dom";

import verifMail from '../../utils/verifMail';
import { getCustomer } from '../../callApi/getCustomerById';
import { getSubscriptions } from '../../callApi/getStripeSubscription'
import { getContactActiveCampaign } from '../../callApi/ActiveCampaign'
import { findRowByMailReporting, findRowByMailTestNewVip, findRowAcceptations } from '../../callApi/sheets'

//Composant servant a l'utilisateur pour rentrer la mail du client
// Une fois le mail rentré, il est renvoyé au composant parent via 'callbackFunction' (fonction modifiant le parent)

const ChampsRechercheMail = (props) => {

  const [state, setState] = React.useState({
    email: `${props.email !== "null" ? props.email : ""}`
  })

  let navigate = useNavigate();

  const handleSubmit = async (e) => {

    //reset du state
    props.callbackReload()

    if (verifMail(state.email) === false) {

      props.callbackFunction({
        customer: null,
        subs: null,
        activeCampaignId: null,
        rowReporting: null,
        rowTestNewVip: null,
        rowAcceptations: null,
        search: false,
        message: 'Veuillez renseigner un mail valide'
      })

    } else {

      navigate(`../?email=${state.email}${props.admin ? "&admin=true" : ``}`, { replace: true });

      props.callbackFunction({
        email: state.email,
        search: true,
        message: 'Recherche en cours'
      })

      //recup du customer en bdd
      const res = await getCustomer(state.email)

      let subs, contactAC, rowAcceptations, rowReporting, rowTestNewVip
      if (!!res) {

        //envoi
        props.callbackFunction({
          customer: res
        })

        //recherche des subscriptions
        subs = await getSubscriptions({ email: state.email })
        //envoi
        props.callbackFunction({
          subs: subs
        })

        //recherche dans active campaign
        contactAC = await getContactActiveCampaign({ email: state.email })
        //envoi
        props.callbackFunction({
          activeCampaignId: contactAC
        })

        //recherche des Test new vip
        rowTestNewVip = await findRowByMailTestNewVip({ email: state.email })
        //envoi
        props.callbackFunction({
          rowTestNewVip: rowTestNewVip
        })

        //recherche dans reporting
        rowReporting = await findRowByMailReporting({ email: state.email, product_id: res.product_id.id })
        //envoi
        props.callbackFunction({
          rowReporting: rowReporting
        })

        //recherhce dans suivi des accepation
        rowAcceptations = await findRowAcceptations({ email: state.email, product_id: res.product_id.id })
        //envoi
        props.callbackFunction({
          rowAcceptations: rowAcceptations
        })

        props.callbackFunction({
          search: null,
          message: null
        })

      } else {

        props.callbackReload()

        props.callbackFunction({
          search: false,
          message: 'Client non trouvé en Base de données'
        })
      }
    }
  }

  //handlesubmit au chargement de la page
  useEffect(() => {
    handleSubmit()
  }, [props.email])

  return (
    <form
      className="flex flex-wrap m-0 p-0 align-items-center justify-content-center"
      onSubmit={(e) => { e.preventDefault(); handleSubmit(e) }}>
      <div className="flex justify-content-start">
        Mail du client :
      </div>
      <div>
        <input type="text" name="name" value={state.email} onChange={(e) => { setState({ email: e.target.value }) }} onSubmit={handleSubmit} />
      </div>
      <button type="button" onClick={handleSubmit}> Valider </button>
    </form>
  )
}


export default ChampsRechercheMail;