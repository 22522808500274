
import url from "./configUrl"
import axios from 'axios';


const urlBdd = `${url}/graphQl`


export const getSellers = async () => {
  //getSellers
  const sellers = await axios({
    method: 'post',
    url: urlBdd,

    data: {
      query: `
            query getSellers{
              getSellers{
                  id
                  firstname
                  lastname
      
          }
      }
              `,
    }

  })
  if (sellers) {
    return sellers.data.data.getSellers
  } else {
    return null
  }
}
