import React, { useState, useContext } from 'react';
import { UserContext } from "../../context/userContext"
import { Card } from 'primereact/card';
import { Message } from 'primereact/message';
import { Button } from 'primereact/button';
import { Password } from 'primereact/password';
import { Dropdown } from 'primereact/dropdown';
import { InputText } from 'primereact/inputtext';
import generator from 'generate-password'

const AddBackUser = () => {

    const { signUp } = useContext(UserContext);

    const [validation, setValidation] = useState('');
    const [success, setSuccess] = useState('');

    const [inputs, setInputs] = useState({
        email: '',
        password: '',
        password_repeat: '',
        userName: '',
        cellule: 'IT',
        role: 'Member',
        ConfirmCode: ''
    })

    const handleForm = async () => {
        console.log('inputs:', inputs)

        if ((inputs.password.length || inputs.password_repeat.length) < 6) {
            setValidation("6 characteres min")
            return;
        }
        else if (inputs.password !== inputs.password_repeat) {
            setValidation("Passwords do not match")
            return;
        }

        try {

            const sign = await signUp({
                email: inputs.email,
                password: inputs.password,
                userName: inputs.userName,
                cellule: inputs.cellule,
                role: inputs.role
            })
            console.log('sign.status:', sign.status)
            if (!sign.status) {
                throw sign
            }

            setValidation("")
            setSuccess(`Utilisateur enregistré avec succès\ninformations a donner:\nemail: ${inputs.email}\nMot de passe: ${inputs.password}`)
        } catch (err) {
            setValidation(err.error)
        }
    }

    const generatePassword = () => {

        let password = generator.generate({
            length: 10,
            numbers: true,
            symbols: true,
            lowercase: true,
            uppercase: true,
            strict: true
        });

        setInputs({
            ...inputs,
            password: password,
            password_repeat: password
        })
    }

    return (
        <div
            className='flex justify-content-center'
        >

            <Card className="w-max h-auto flex align-items-center m-1">

                <div className="flex-column flex m-2 text-left">
                    <label htmlFor="signUpEmail">
                        Email
                    </label>
                    <InputText
                        id="signUpEmail"
                        value={inputs.email}
                        onChange={(e) => setInputs({ ...inputs, email: e.target.value })}
                    />
                </div>

                <div className="flex-column flex m-2 text-left">
                    <label htmlFor="userName">
                        Nom d'utilisateur
                    </label>
                    <InputText
                        id="userName"
                        value={inputs.userName}
                        onChange={(e) => setInputs({ ...inputs, userName: e.target.value })}
                    />
                </div>

                <div className="flex-column flex m-2 text-left">
                    <label htmlFor="signUpCellule" >
                        Cellule
                    </label>
                    <Dropdown
                        id="signUpCellule"
                        options={[{ name: "IT", value: "IT" }, { name: "SAV", value: "SAV" }]}
                        value={inputs.cellule}
                        onChange={(e) => setInputs({ ...inputs, cellule: e.target.value })}
                        optionLabel="name"
                    />
                </div>

                <div className="flex-column flex m-2 text-left">
                    <label htmlFor="signUpRole" >
                        Rôle
                    </label>
                    <Dropdown
                        id="signUpRole"
                        options={[{ name: "Member", value: "Member" }, { name: "Leader", value: "Leader" }]}
                        value={inputs.role}
                        onChange={(e) => setInputs({ ...inputs, role: e.target.value })}
                        optionLabel="name"
                    />
                </div>

                <div className="flex-column flex m-2 text-left">
                    <label htmlFor="password">
                        Mot de passe
                    </label>
                    <Password
                        toggleMask
                        id="password" feedback={false}
                        value={inputs.password}
                        onChange={(e) => setInputs({ ...inputs, password: e.target.value })}
                    />
                </div>

                <div className="flex-column flex m-2 text-left">
                    <label htmlFor="repeatPwd">
                        Répeter le mot de passe
                    </label>
                    <Password
                        toggleMask
                        id="repeatPwd" feedback={false}
                        value={inputs.password_repeat}
                        onChange={(e) => setInputs({ ...inputs, password_repeat: e.target.value })}
                    />
                </div>
                <div className="flex justify-content-rignt">
                    <Button
                        icon="pi pi-lock"
                        className="p-button-link"
                        onClick={() => generatePassword()}
                        label={`Générer un mot de passe`}
                    />
                </div>

                <div className="mb-3 flex-column">
                    <div className="mb-1">
                        {!!validation &&
                            <Message
                                severity="warn"
                                text={validation}
                            />
                        }
                    </div>
                    <div className="mb-1">
                        {!!success &&
                            <Message
                                severity="success"
                                text={
                                    success.split("\n").map(text => {
                                        return <div>{text}</div>
                                    })
                                }
                            />
                        }
                    </div>
                </div>
                <div className="flex justify-content-center m-2">
                    <Button
                        className="p-button-info"
                        onClick={() => handleForm()}
                        label={`Enregistrer`}
                    />
                </div>
            </Card>

        </div>
    )
}
export default AddBackUser