import React from 'react';
import { Card } from 'primereact/card';
import moment from 'moment';
import { confirmPopup } from 'primereact/confirmpopup';


const Customer = (props) => {

    //convertion de la date
    const convertDate = (date) => {
        const newTime = new Intl.DateTimeFormat('fr-FR', { year: 'numeric', month: '2-digit', day: '2-digit' }).format(date)
        const splitDate = newTime.split("/")
        return `${splitDate[0]}/${splitDate[1]}/${splitDate[2]}`
    }
    //compte les jours
    const nbJours = (date) => {
        console.log('date:', date)
        const today = new Date()
        var Diff_temps = moment(today, "DD-MM-YYYY") - moment(date, "DD-MM-YYYY")
        var Diff_jours = Diff_temps / (1000 * 3600 * 24)
        return Math.round(Diff_jours)
    }


    return (
        <>
            <Card title="Infos Client" className='flex flex-1 m-1 align-items-center justify-content-center'>
                <div>
                    {props.customer.firstname} {props.customer.lastname} - {props.customer.product_id.name}
                </div>
                {
                    props.customer.coach_id !== null &&
                    <div>
                        <span className="font-bold">Coach: </span>
                        {props.customer.coach_id.firstname} {props.customer.coach_id.lastname}
                    </div>
                }
                {
                    props.customer.seller_id !== null &&
                    <div>
                        <span className="font-bold">Vendeur: </span>
                        {props.customer.seller_id.firstname} {props.customer.seller_id.lastname}
                    </div>
                }
                {
                    props.customer.sale !== null &&
                    <>
                        <div>
                            <span className="font-bold">Réglement via: </span>
                            {props.customer.sale.processor}
                        </div>
                    </>
                }
                {
                    props.customer.created_at !== null &&
                    <div>
                        <span className="font-bold">Arrivé le: </span>
                        {convertDate(props.customer.created_at)}
                    </div>
                }
                {
                    props.customer.end_date !== null ?
                        <div>
                            <span className="font-bold">Départ le: </span>
                            {convertDate(props.customer.end_date)}
                        </div>
                        :
                        <div>
                            <span className="font-bold"> Jour n° {nbJours(convertDate(props.customer.created_at))}</span>
                        </div>
                }
            </Card>
        </>
    )
}


export default Customer;