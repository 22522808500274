/*eslint no-unused-vars: "off"*/
import React from 'react';
import { Button } from 'primereact/button';
import { Card } from 'primereact/card';
import { ProgressSpinner } from 'primereact/progressspinner';
import { Tag } from 'primereact/tag';
import { listAllAlmaPayment } from '../../callApi/listAllAlmaPayment';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import { Message } from 'primereact/message';

const AlmaList = (props) => {

    const [list, setList] = React.useState(null)
    const [copy, setCopy] = React.useState(false)
    const [url, setUrl] = React.useState(null)

    React.useEffect(() => {
        get()
    }, [props.email])

    const get = async () => {
        const res = await listAllAlmaPayment(props.email)
        //suppression des paiments échoués
        let tempList = []
        for (const pay of res.data) {
            if (pay.state === "paid" || pay.state === "in_progress") {
                tempList.push(pay)
            }
        }
        setList(tempList)
    }

    const changeUrl = (id) => {
        setUrl(`https://checkout.getalma.eu/${id}`)
    }
    //go portal
    const goPortal = async (url) => {
        window.open(url)
    }

    //convertion de la date
    const convertDate = (date) => {
        const newTime = new Intl.DateTimeFormat('fr-FR', { year: 'numeric', month: '2-digit', day: '2-digit' }).format(date * 1000)
        const splitDate = newTime.split("/")
        return `${splitDate[0]}/${splitDate[1]}/${splitDate[2]}`
    }

    return (
        url ?
            <Card>

                <h4 className='overflow-hidden text-overflow-ellipsis'>{url}</h4>
                <CopyToClipboard text={url}
                    onCopy={() => setCopy(true)}>
                    <Button
                        className={copy ? " m-1 p-button-success" : "m-1 p-button-info"} >
                        <i className={copy ? "m-1 pi pi-check-square" : "m-1 pi pi-copy"} />
                        {copy ? "Adresse copiée" : "Copier l'adresse"}
                    </Button>
                </CopyToClipboard>
                <Button
                    className='m-1 p-button-info'
                    onClick={() => goPortal(url)} >
                    <i className="m-1 pi pi-credit-card" />
                    aller au portail du paiement
                </Button>
            </Card>
            :
            list === null ?
                <ProgressSpinner className="p-1 text-sm" style={{ width: '40px', height: '40px' }} fill="#ffffff" strokeWidth="10" animationDuration="1.5s" />
                :
                list.length !== 0 ?
                    list.map(pay =>
                        <Card className='m-1 overflow-hidden text-overflow-ellipsis'>
                            <div className='m-1 '>
                                <h2>client:</h2>
                                <strong>Nom: </strong>{pay.customer.last_name} {pay.customer.first_name} <strong >Id alma: </strong>({pay.customer.id})
                            </div>
                            <div className='m-1'>
                                <h2>paiement:</h2>
                                <strong>Date: </strong>{convertDate(pay.created)} <strong>Total: </strong>{pay.purchase_amount / 100} Eur <strong>Reste à payer: </strong>{pay.amount_left_to_pay / 100} Eur
                            </div>
                            <div>
                                <Tag className='text-sm m-1' value={pay.state} severity={pay.state === 'paid' ? "success" : "warn"} />
                            </div>

                            <div>
                                <Button
                                    className='p-button-info'
                                    onClick={() => changeUrl(pay.id)} >
                                    <i className="m-1 pi pi-credit-card" />
                                    Portail Alma
                                </Button>
                            </div>
                        </Card>
                    )
                    :
                    <Tag className='text-sm m-1' value="Aucun paiement alma" severity="info" />
    )
}


export default AlmaList;