/*eslint no-unused-vars: "off"*/
import React from 'react';
import { Button } from 'primereact/button';
import { Card } from 'primereact/card';


const EspacesClient = (props) => {
    let payment_id_alma
    try {
        payment_id_alma = props.sale.detail.payment_id
    } catch (error) {
        payment_id_alma = ""
    }
    console.log('payment_id_alma:', payment_id_alma)

    const urls = {
        "kajabi": `https://app.kajabi.com/admin/sites/97785/contacts?page=1&search=${props.email}`,
        "active-campaign": `https://alphabody.activehosted.com/app/contacts?q=${props.email}`,
        "helpscout": `https://secure.helpscout.net/search/?query=${props.email}`,
        "stripe": `https://dashboard.stripe.com/${props.sale.processor_cus_id ? `customers/${props.sale.processor_cus_id}` : `search?query=${props.email}`}`,
        "alma": `https://dashboard.getalma.eu/payments/${payment_id_alma}`,
        "tnv": `https://docs.google.com/spreadsheets/d/1bbsNgP1o6WFLXQHWbg7NbvxhOuC39-gjfkbq5udqxxM/edit#gid=0&range=E${props.rowTestNewVip.row}`,
        "reporting": `https://docs.google.com/spreadsheets/d/1ghdODfogPKSlMeVNnzmZpc_2ynr0Enw7e3O1rK_dxYM/edit#gid=${props.rowReporting.sheetId}&range=C${props.rowReporting.row}`,
        "acceptations": `https://docs.google.com/spreadsheets/d/1GCf52wLPK9Ceqdq5eJuZ3Swy--QtePRFKnjTpQfn0yM/edit#gid=${props.rowAcceptations.sheetId}&range=E${props.rowAcceptations.row}`
    }

    const click = (button) => {
        console.log('button:', button)
        window.open(`${urls[button]}`)
    }

    return (

        <Card title="Espaces Client" className='flex flex-1 m-1 align-items-center justify-content-center'>
            <div >

                <Button
                    className="m-1 p-button-info"
                    onClick={(e) => click("kajabi")}
                    label="Kajabi"
                />

                <Button
                    className="m-1 p-button-info"
                    onClick={(e) => click("active-campaign")}
                    label='Active Campaign'
                />

                <Button
                    className="m-1 p-button-info"
                    onClick={(e) => click("helpscout")}
                    label='Helpscout'
                />

                {
                    props.sale.processor.toLowerCase() === "stripe" &&
                    <Button
                        className="m-1 p-button-help"
                        onClick={(e) => click("stripe")}
                        label='Stripe'
                    />
                }
                {
                    props.sale.processor.toLowerCase() === "alma" &&
                    <Button
                        className="m-1 p-button-help"
                        onClick={(e) => click("alma")}
                        label='Alma'
                    />
                }


                {
                    props.customer.product_id.name.includes("VIP") &&
                    <Button
                        className="m-1 p-button-success"
                        onClick={(e) => click("tnv")}
                        label='Test New Vip'
                    />
                }

                <Button
                    className="m-1 p-button-success"
                    onClick={(e) => click("reporting")}
                    label='Reporting'
                />

                <Button
                    className="m-1 p-button-success"
                    onClick={(e) => click("acceptations")}
                    label='Acceptations'
                />

            </div >

        </Card >

    )
}


export default EspacesClient;