import axios from 'axios';
import {config} from "dotenv";
config()

const urlBdd = `${process.env.REACT_APP_API_GRAPHQL}/graphQl`

export const getCustomer = async (email) => {
  //getCustomerByMail
  const customer = await axios({
    method: 'post',
    url: urlBdd,

    data: {
      query: `
                  query getCustomerByMail($email: String!){ 
                  getCustomerByMail(email: $email){
                      id,
                      email,
                      firstname,
                      lastname,
                      tel,
                      password,
                      dateofbirth,
                      activ,
                      annulation,
                      created_at,
                      updated_at,
                      last_connection,
                      ambassador,
                      alpha_point,
                      end_date,
                      telegram,
                      
                        coach_id{
                          id, 
                          firstname, 
                          lastname, 
                          telegram
                        },
                      
                        product_id{
                          id, 
                        gender, 
                        name
                        },

                        sale{
                          id,
                          processor,
                          processor_cus_id,
                          detail
                        },

                        seller_id{
                            id,
                            firstname,
                            lastname
                        },

                  }
              }
              `,
      variables: {
        "email": email
      }
    }

  })
  if (customer) {
    return customer.data.data.getCustomerByMail
  } else {
    return null
  }
}
