

import ZonePaymentIntents from '../../../components/ZonePaymentIntents';
import ZoneSubscriptions from '../../../components/ZoneSubscriptions';
import Remboursement14jours from '../../../components/Remboursement14jours';
import RemboursementArret from '../../../components/RemboursementArret';
import CheckDoublePayement from '../../../components/CheckDoublePayement';
import Invoices from '../../../components/Invoices';
import ArretsDiff from '../../../components/ArretDiff';
import VipToForm from '../../../components/VipToForm';
import StripeList from '../../../components/stripeList';
import AlmaList from '../../../components/almaList';
import React, { useContext } from 'react'
import { Message } from 'primereact/message';
import { Accordion, AccordionTab } from 'primereact/accordion';

import { infosSection } from './infoSections';
import MsgCoach from '../../../components/MsgCoach';
import { Card } from 'primereact/card';
import Renewal from '../../../components/Renewal';

function Rembourssements(props) {
  //console.log('props:', props)
  //affichage des infos de sections
  React.useEffect(() => {
    setActiveIndex({
      section: null,
      infos: null,
      showInfos: true
    })
  }, [props.state.email])

  //gestion de l'accordeon
  const [activeIndex, setActiveIndex] = React.useState({
    section: null,
    infos: "Infos Section",
    showInfos: false
  });

  return (
    <Card className="m-1 p-0">

      {
        props.state.customer &&
        <>

          <div>

            {
              (!!activeIndex.section || activeIndex.section === 0) &&
              <div className="m-1" onClick={(e) => { setActiveIndex({ ...activeIndex, showInfos: !activeIndex.showInfos }) }}>
                <Message
                  icon="pi pi-info"
                  severity="info"
                  text={activeIndex.showInfos ?
                    activeIndex.infos.split("\n").map((i, key) => {
                      return <div key={key}>{i}</div>
                    })
                    : "Infos Section"
                  }
                />
              </div>
            }

            <div >
              <Accordion activeIndex={activeIndex.section} onTabChange={(e) => { console.log(e); setActiveIndex({ ...activeIndex, section: e.index, infos: infosSection[`${e.index}`].infos }) }}>
                {/*ID  0*/}
                <AccordionTab header="Annulation 14 jours">
                  <Remboursement14jours id="annul14js" email={props.state.email} customer={props.state.customer} />
                </AccordionTab>
                {/*ID  1*/}
                <AccordionTab header="Arrêts">
                  <RemboursementArret email={props.state.email} customer={props.state.customer} />
                </AccordionTab>
                {/*ID  2*/}
                <AccordionTab header="Arrets différés">
                  <ArretsDiff email={props.state.email} customer={props.state.customer} subs={props.state.subs} rowTnv={props.state.rowTestNewVip} />
                </AccordionTab>
                {/*ID  3*/
                  ["3", "4"].includes(props.state.customer.product_id.id) &&
                  <AccordionTab header="Vip to Form">
                    <VipToForm email={props.state.email} customer={props.state.customer} subs={props.state.subs} />
                  </AccordionTab>
                }
                {/*ID  4*/
                  ["3", "4"].includes(props.state.customer.product_id.id) &&
                  <AccordionTab header="Message au coach">
                    <MsgCoach customer={props.state.customer} />
                  </AccordionTab>
                }
                {/*ID  5*/}
                <AccordionTab header="Renouvellement abonement">
                  <Renewal customer={props.state.customer} />
                </AccordionTab>
                {/*ID  6*/}
                <AccordionTab header="Factures Quaderno">
                  <Invoices email={props.state.email} />
                </AccordionTab>
                {/*ID  7*/}
                <AccordionTab header="Liste stripe">
                  <StripeList email={props.state.email} />
                </AccordionTab>
                {/*ID  8*/}
                <AccordionTab header="Liste alma">
                  <AlmaList email={props.state.email} />
                </AccordionTab>
                {/*
                  props.state.customer.sale.processor === "stripe" &&

                  < AccordionTab header="Vérification Double paiement">
                    <CheckDoublePayement email={props.state.email} customer={props.state.customer} />
                  </AccordionTab>
                }
                {
                  props.state.customer.sale.processor === "stripe" &&
                  <AccordionTab header="Abonnements Stripe">
                    <ZoneSubscriptions callbackReload={props.callbackReload} email={props.state.email} customer={props.state.customer} subs={props.state.subs} />
                  </AccordionTab>
                }
                {
                  props.state.customer.sale.processor === "stripe" &&
                  <AccordionTab header="Paiments Stripe">
                    <ZonePaymentIntents refresh={props.state.refresh} email={props.state.email} customer={props.state.customer} />
                  </AccordionTab>
                */}

              </Accordion>

            </div>
          </div>
        </>
      }
    </Card>
  );
}

export default Rembourssements;
