/*eslint no-unused-vars: "off"*/
import React from 'react';
import Loader from "react-loader-spinner";

import { sendAPIremboursement14jours } from '../../callApi/sendApiRembourssement14Jours';

import { Tag } from 'primereact/tag';

// Composant contenant le module de remboursement sous 14 jours
// Contient une description (texte) et un bouton lancant le remboursement via une requete a l'API GraphQL
const Remboursement14jours = (props) => {

    let [error, setError] = React.useState({
        success: null,
        message: []
    })

    let [state, setState] = React.useState({
        email: props.email,
        callbackReload: props.callbackReload,
        isButtonEnable: true
    });

    React.useEffect(() => {
        setState({ ...state, email: props.email })
        // eslint-disable-next-line
    }, [props.email]);

    // Click sur le bouton
    const click = async () => {
        // desactivation du bouton
        setState({ ...state, isButtonEnable: false })
        setError({
            success: null,
            message: []
        })

        // requete a l'API
        const reponse = await sendAPIremboursement14jours(state.email)
        console.log('reponse:', reponse)
        setError({
            success: reponse.status,
            message: reponse.message
        })
        // reactivation du bouton
        setState({ ...state, isButtonEnable: true })

    }

    return (
        <div>
            <div className="block">
                <button type='button' onClick={click} disabled={!state.isButtonEnable}>Remboursement 14 jours</button>
            </div>
            {!state.isButtonEnable ? <Loader type="Rings">Load</Loader> : null}
            {
                error.success !== null &&
                error.message.map((item) =>
                    <div>
                        <Tag className="m-1 text-xl" severity={item.includes("Erreur") ? "danger" : "success"} value={item} key={item} />
                    </div>
                )
            }
        </div>
    )
}


export default Remboursement14jours;