
import url from "./configUrl"
import axios from 'axios';


const urlBdd = `${url}/graphQl`


export const getCoachs = async () => {
  //getCoachs
  const coach = await axios({
    method: 'post',
    url: urlBdd,

    data: {
      query: `
            query getCoachs{
              getCoachs{
                  id
                  firstname
                  lastname
      
          }
      }
              `,
    }

  })
  if (coach) {
    return coach.data.data.getCoachs
  } else {
    return null
  }
}
