
import axios from 'axios';

export const findRowByMailReporting = async (data) => {

    const url = `https://api-rest.alphabody.fr:2053/sheet/mail/reporting?email=${data.email}&product_id=${data.product_id}`

    const getRow = await axios({
        method: 'get',
        url: url,
    })

    if (getRow) {
        return getRow.data
    } else {
        return null
    }
}

export const findRowByMailTestNewVip = async (data) => {

    const url = `https://api-rest.alphabody.fr:2053/sheet/mail/testnewvip?email=${data.email}`

    const getRow = await axios({
        method: 'get',
        url: url
    })

    if (getRow) {
        return getRow.data
    } else {
        return null
    }
}

export const findRowAcceptations = async (data) => {

    const url = `https://api-rest.alphabody.fr:2053/sheet/mail/suiviacceptations?email=${data.email}&product_id=${data.product_id}`

    const getRow = await axios({
        method: 'get',
        url: url
    })

    if (getRow) {
        return getRow.data
    } else {
        return null
    }
}

export const modifSheet = async (data) => {

    const url = `https://api-rest.alphabody.fr:2053/sheet`

    const modifRow = await axios({
        method: 'post',
        url: url,
        data: data
    })

    if (modifRow) {
        return modifRow.data
    } else {
        return null
    }
}
