import axios from 'axios';

export const listAllCustomerByMailWithSubscription = async (email) => {

    const url = `${process.env.REACT_APP_URL_API_FACTURATION}/stripe/listAllCustomerByMailWithSubscription?email=${String(email)}`

    const response = await axios({
        method: 'get',
        url: url,
    })

    if (response.status === 200) {
        return response.data
    }

}
