import { createContext, useState, useEffect } from "react";

//config de cognito
import Amplify, { Auth, Hub } from 'aws-amplify'

Amplify.configure({
  Auth: {
    region: process.env.REACT_APP_COGNITO_REGION,
    userPoolId: process.env.REACT_APP_COGNITO_USER_POOL_ID,
    userPoolWebClientId: process.env.REACT_APP_COGNITO_USER_POOL_WEB_CLIENT_ID,
  },
})

export const UserContext = createContext()

export function UserContextProvider(props) {

  //indo sur le User connecté
  const [currentUser, setCurrentUser] = useState();
  //variable d'attente d'info de cognito
  const [loadingData, setLoadingData] = useState(true);

  //enregistrement 
  const signUp = async (infos) => {
    try {
      const sign = await Auth.signUp({
        username: infos.email,
        password: infos.password,
        attributes: {
          "name": infos.userName,
          "custom:cellule": infos.cellule,
          "custom:role": infos.role
        },
      }).then(res => {
        setLoadingData(false)
        return ({
          status: true,
          res: res
        })
      })
        .catch(error => {
          setLoadingData(false)
          return ({
            status: false,
            error: error.message
          })
        })
      return sign
    } catch (e) {
      setLoadingData(false)
      return ({
        status: false,
        error: 'Signup fail.'
      })
    }
  }

  //confirmation de l'adresse mail
  async function confirmCode(infos) {
    try {
      const confirmSignUp = await Auth.confirmSignUp(infos.email, infos.confirmCode);
      return ({
        status: true,
      })
    } catch (error) {
      return ({
        status: false,
        error: error.message
      })
    }
  }

  async function resendConfirmationCode(infos) {
    try {
      await Auth.resendSignUp(infos.email);
      return ({
        status: true,
      })
    } catch (error) {
      return ({
        status: false,
        error: error.message
      })
    }
  }

  //login
  const signIn = async (infos) => {
    try {
      const user = await Auth.signIn({
        username: infos.email,
        password: infos.password,
      }).then(res => {
        setCurrentUser(res)
        setLoadingData(false)
        return ({
          status: true,
          res: res
        })
      }).catch(err => {
        setCurrentUser(null)
        setLoadingData(false)
        return ({
          status: false,
          error: err.message
        })
      })
      return user
    } catch (e) {
      setCurrentUser(null)
      setLoadingData(false)
      return ({
        status: false,
        error: 'signIn fail. Error'
      })
    }
  }

  //signOut
  const signOut = async () => {
    try {
      await Auth.signOut({ global: true })
      setCurrentUser(null)
      return true
    } catch (e) {
      return false
    }
  }

  //verification si user connecté
  const checkUser = async (currentUser) => {
    try {
      const user = await Auth.currentUserPoolUser()
      setCurrentUser(user)
    } catch (err) { console.log(err) }
    setLoadingData(false)
  }

  useEffect(() => {

    checkUser()
    Hub.listen('auth', (data) => {
      const { payload } = data
      if (payload.event === 'signOut') {
        setCurrentUser(null)
      }
    })
  }, [])

  //envoi du code de vérification en cas d'oubli de mot de passe
  const sendCodeForgotPassword = async (email) => {
    const res = await Auth.forgotPassword(email)
      .then(data => {
        return ({
          status: true,
          res: data
        })
      })
      .catch(err => {
        return ({
          status: false,
          error: err
        })
      });
    return res
  }

  //confirmation du nouveau mot de passe
  const changeForgotPassword = async (infos) => {
    const res = await Auth.forgotPasswordSubmit(infos.username, infos.code, infos.new_password)
      .then(data => {
        return ({
          status: true,
          res: data
        })
      })
      .catch(err => {
        return ({
          status: false,
          error: err
        })
      });
    return res
  }

  // modal
  const [modalState, setModalState] = useState({
    signUpModal: false,
    signInModal: false
  })

  const toggleModals = modal => {
    if (modal === "signIn") {
      setModalState({
        signUpModal: false,
        signInModal: true
      })
    }
    if (modal === "signUp") {
      setModalState({
        signUpModal: true,
        signInModal: false
      })
    }
    if (modal === "close") {
      setModalState({
        signUpModal: false,
        signInModal: false
      })
    }
  }

  return (
    <UserContext.Provider value={{ modalState, toggleModals, signUp, currentUser, signIn, signOut, confirmCode, resendConfirmationCode, sendCodeForgotPassword, changeForgotPassword }}>
      {!loadingData && props.children}
    </UserContext.Provider>
  )
}