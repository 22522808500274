
import configUrl from './configUrl'

export const cancelSubsEndPeriod = async (data) => {

    const dataSend = {
        subs: data.subs,
        action: data.action
    }

    console.log('dataSend:', dataSend)
    const url = `${configUrl}/subscription/stripe/cancelSubsEndPeriod`

    const init = {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json'
        },
        body: JSON.stringify(dataSend)
    }

    let response
    try {
        response = await fetch(url, init).then((response) => {
            if (!response.ok) {
                throw new Error("HTTP status " + response.status);
            }
            return response.json();
        })
    } catch (error) {
        response = error
    }


    console.log('response:', response)
    return response
};