
import configUrl from './configUrl'
import axios from 'axios';

export const vipToForm = async (data) => {
    console.log('data Vip --> Form:', data)
    let result

    let tempDate = new Date(parseInt(data.customer.created_at))
    let dateStart = tempDate.toLocaleString('fr-FR', { year: "numeric", month: "numeric", day: "numeric" })

    //recup du sub vip actif
    let sub = []
    for (let item of data.subs) {
        if (item.status === 'active') {
            sub.push(item.id)
        }
    }
    if (sub.length !== 1) {
        return ({
            status: "ECHEC",
            message: "Probleme sur la récupération de l'abbonnement stripe"
        })
    }

    //creation de l'event
    const dataToSend = {
        "author": data.infos.author,
        "customer": {
            "email": data.customer.email,
            "firstname": data.customer.firstname,
            "lastname": data.customer.lastname,
            "gender": parseInt(data.customer.product_id.id) === 3 ? "Homme" : "Femme",
            "dateStart": dateStart,//
            "id": parseInt(data.customer.id)
        },
        "product": {
            "sub": sub[0],
            "payMethod": data.infos.payMethod,
            "period": data.infos.period,
            "oldProductId": parseInt(data.customer.product_id.id)
        },
        "seller": {
            "firstname": data.customer.seller_id.firstname,
            "lastname": data.customer.seller_id.lastname
        },
        "coach": {
            "id": data.customer.coach_id.id
        }
    }
    //verification avant envoi
    for (let niv1 in dataToSend) {
        if (dataToSend[niv1] instanceof Object) {
            for (let niv2 in dataToSend[niv1]) {
                if (!!!dataToSend[niv1][niv2]) {
                    return ({
                        status: "ECHEC",
                        message: "Des données sont manquantes"
                    })
                }
            }
        } else {
            if (!!!dataToSend[niv1]) {
                return ({
                    status: "ECHEC",
                    message: "Des données sont manquantes"
                })
            }
        }
    }

    //execute
    result = await execute(dataToSend)

    //analyse de l'execute
    let messageError = ""
    //modif tnv
    if (result.modifTnv.modif.statusCode !== 200) {
        messageError = `Echec de la modification du fichier test new vip`
    }
    //modif reporting vip
    if (result.modifReportingVip.modif.statusCode !== 200) {
        messageError = `${messageError} \nEchec de la modification du fichier reporting feuille vip`
    }
    //modif reporting form
    if (result.modifReportingForm.modif.statusCode !== 200) {
        messageError = `${messageError} \nEchec de la modification du fichier reporting feuille Formation`
    }
    //ajout dummy reporting form
    if (result.dummyRowReportingForm.statusCode !== 200) {
        messageError = `${messageError}\nEchec de l'ajout du dummy sur le fichier reporting feuille Formation`
    }
    //modif commish
    if (result.modifCommish.modif.statusCode !== 200 && result.modifCommish.modif !== "N/A") {
        messageError = `${messageError}\nEchec de l'ajout du dummy sur le fichier reporting feuille Formation`
    }
    //stop kajabi
    if (result.stopVipKajabi.statusCode !== 200) {
        messageError = `${messageError}\nEchec de l'arret Kajabi`
    }
    //start kajabi
    if (result.startFormKajabi.statusCode !== 200) {
        messageError = `${messageError}\nEchec du depart Kajabi`
    }
    //add tag AC
    if (result.addTagAc.modif.status !== "OK") {
        messageError = `${messageError}\nEchec de l'ajout du tag Active campaign`
    }
    //update customer product
    if (result.updateCustomerProduct.status !== "OK") {
        messageError = `${messageError}\nEchec de la modification en BDD`
    }
    //change stripe product
    if (result.changeStripeProduct.status !== "OK") {
        messageError = `${messageError}\nEchec du changement de produit stripe`
    }
    //message telegramm
    if (result.sendMsgTelegram.statusCode !== 200) {
        messageError = `${messageError}\nEchec de l'envoi du message au coach`
    }

    console.log('messageError:', messageError)
    if (messageError === "") {
        return ({
            status: "OK",
            message: "Terminé avec succès - merci de modifier la date du produit KAJABI"
        })
    } else {
        return ({
            status: "ECHEC",
            message: messageError
        })
    }
}

async function execute(event) {
    console.log('event:', event)

    //variables globales de date
    const today = new Date()
    const dateFormula = `=DATE(${today.getFullYear()};${parseInt(today.getMonth()) + 1};${parseInt(today.getDate())})`
    const dateString = `${parseInt(today.getDate())}/${parseInt(today.getMonth()) + 1}/${today.getFullYear()}`
    const splitDate = event.customer.dateStart.split('/')
    const dateStart = new Date(splitDate[2], splitDate[1] - 1, splitDate[0], 6, 0, 0)
    var diffJours = parseInt((today.getTime() - dateStart.getTime()) / (1000 * 3600 * 24), 10);


    //changement product + price dans stripe
    let amount = 0
    const changeStripeProduct = await changeProductStripe({
        "sub": event.product.sub,
        "period": event.product.period,
        "newProduct": `Form-${event.customer.gender}`
    })
    if (changeStripeProduct) {
        amount = changeStripeProduct.amount
    }

    //mise en arret test new vip avec remarque
    const rowTnv = await searchInSheet({
        "doc": "TestNewVip",
        "sheet": "Arrivé des VIPs",
        "column": "E",
        "search": event.customer.email
    })
    let modifTnv
    if (rowTnv.row !== "N/A") {
        modifTnv = await modifSheets({
            "doc": "TestNewVip",
            "sheet": "Arrivé des VIPs",
            "row": parseInt(rowTnv.row),
            "modifs": {
                "G": "Arrêt",
                "J": dateFormula
            },
            "notes": {
                "E": `Passage Vip --> Form le ${dateString}`
            },
            "delRow": null
        })
    } else {
        modifTnv = "ECHEC"
    }

    //mise en arret reporting vip
    const leafVip = {
        "Homme": "Suivi-VIP",
        "Femme": "Suivi-VIP-F",
    }
    const rowReportingVip = await searchInSheet({
        "doc": "Reporting",
        "sheet": leafVip[event.customer.gender],
        "column": "C",
        "search": event.customer.email
    })

    let modifReportingVip
    if (rowReportingVip.row !== "N/A") {
        modifReportingVip = await modifSheets({
            "doc": "Reporting",
            "sheet": leafVip[event.customer.gender],
            "row": parseInt(rowReportingVip.row),
            "modifs": {
                "E": "Arrêt",
                "J": dateFormula
            },
            "notes": {
                "C": `Passage Vip --> Form le ${dateString}`
            },
            "delRow": null
        })
    } else {
        modifReportingVip = "ECHEC"
    }

    //creation ligne dans reporting form + remarque
    const leafForm = {
        "Homme": "Suivi-FLAGSHIP",
        "Femme": "Suivi-FORM-F",
    }
    const rowReportingForm = await searchInSheet({
        "doc": "Reporting",
        "sheet": leafForm[event.customer.gender],
        "column": "A",
        "search": "dummy"
    })
    let dummyRowReportingForm, modifReportingForm
    if (rowReportingForm.row !== "N/A") {
        dummyRowReportingForm = await modifSheets({
            "doc": "Reporting",
            "sheet": leafForm[event.customer.gender],
            "row": parseInt(rowReportingForm.row) + 1,
            "modifs": {
                "A": "dummy"
            },
            "notes": {
            },
            "delRow": null
        })
        modifReportingForm = await modifSheets({
            "doc": "Reporting",
            "sheet": leafForm[event.customer.gender],
            "row": parseInt(rowReportingForm.row),
            "modifs": {
                "A": dateFormula,
                "B": `Transfert VIP to Flag - ${event.product.period}`,
                "C": event.customer.email,
                "D": event.author,
                "E": "En Cours",
                "F": amount,
                "I": event.product.payMethod
            },
            "notes": {
                "C": `Passage Vip --> Form le ${dateString}`
            },
            "delRow": null
        })
    } else {
        dummyRowReportingForm = "ECHEC"
        modifReportingForm = "ECHEC"
    }

    //gestion commish si moins de 14js
    let modifCommish = "N/A"
    let modifCommishRow = "N/A"
    if (diffJours < 15) {
        const seller_firstname = event.seller.firstname;
        const seller_lastname = event.seller.lastname;
        const sheetName = `${seller_firstname}_${seller_lastname}`
        const days = Math.round((dateStart - new Date(1899, 11, 30)) / 8.64e7);
        const rowSale = await searchInSheet({
            "doc": "LeadsAlphabody",
            "sheet": sheetName,
            "column": "A",
            "search": days
        })

        if (rowSale.row !== "N/A") {
            modifCommishRow = rowSale.row
            //recup des valeurs actuelles
            let vipMensuel = parseInt(rowSale.dataRow.D, 10)
            if (!!!vipMensuel) { vipMensuel = 0 }
            let vipAnnuel = parseInt(rowSale.dataRow.E, 10)
            if (!!!vipAnnuel) { vipAnnuel = 0 }
            let form = parseInt(rowSale.dataRow.F, 10)
            if (!!!form) { form = 0 }
            //modif des valeurs
            form = form + 1
            if (event.product.period === "Annuel") {
                vipAnnuel = vipAnnuel - 1
            } else {
                vipMensuel = vipMensuel - 1
            }

            modifCommish = await modifSheets({
                "doc": "LeadsAlphabody",
                "sheet": sheetName,
                "row": parseInt(rowSale.row),
                "modifs": {
                    "D": vipMensuel,
                    "E": vipAnnuel,
                    "F": form,
                },
                "notes": {
                    "F": `Passage Vip --> Form`
                },
                "delRow": null
            })
            console.log('modifCommish:', modifCommish)
        } else {
            modifCommish = "ECHEC"
        }
    }

    //annulation VIP KAJABI
    const stopVipKajabi = await kajabi({
        "firstname": event.customer.firstname,
        "lastname": event.customer.lastname,
        "email": event.customer.email,
        "product_id": event.product.oldProductId,
        "action": "stop"
    })

    //activation Form Kajabi
    let newProductId
    if (event.customer.gender === "Femme") {
        newProductId = 2
    } else {
        newProductId = 1
    }
    const startFormKajabi = await kajabi({
        "firstname": event.customer.firstname,
        "lastname": event.customer.lastname,
        "email": event.customer.email,
        "product_id": newProductId,
        "action": "start"
    })

    //ajout tag Flagship dans active campaign
    let modifAc
    const contactAc = await getActiveCampaignContact({
        "email": event.customer.email
    })
    if (contactAc !== null) {
        modifAc = await addActiveCampaigntag({
            "id": contactAc,
            "tag": "15"
        })
    } else {
        modifAc = "ECHEC"
    }

    //modif product customer in bdd
    let modifCustomer
    const updateCustomerProduct = await updateCustomer({
        "id": event.customer.id,
        "product_id": newProductId
    })
    if (parseInt(updateCustomerProduct.id) === parseInt(event.customer.id)) {
        modifCustomer = {
            status: "OK",
            id: parseInt(updateCustomerProduct.id)
        }
    } else {
        modifCustomer = {
            status: "ECHEC",
            id: "N/A"
        }
    }

    //envoi du message d'arret au coach
    let sendMsgTelegram = await sendMsg({
        "recipient": "coach",
        "id": parseInt(event.coach.id),
        "message": `${event.customer.firstname} ${event.customer.lastname} - ${event.customer.email} Arrêt le ${dateString}`
    })

    // results
    const response = {
        statusCode: 200,
        modifTnv: {
            row: rowTnv.row,
            modif: modifTnv
        },
        modifReportingVip: {
            row: rowReportingVip.row,
            modif: modifReportingVip
        },
        modifReportingForm: {
            row: rowReportingForm.row,
            modif: modifReportingForm
        },
        dummyRowReportingForm: dummyRowReportingForm,
        modifCommish: {
            row: modifCommishRow,
            modif: modifCommish
        },
        stopVipKajabi: stopVipKajabi,
        startFormKajabi: startFormKajabi,
        addTagAc: {
            contact: contactAc,
            modif: modifAc
        },
        updateCustomerProduct: modifCustomer,
        changeStripeProduct: changeStripeProduct,
        sendMsgTelegram: sendMsgTelegram
    };
    return response;
};

////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
async function searchInSheet(dataSearch) {

    //transformation de la data en query
    let queryTab = []
    for (let item in dataSearch) {
        queryTab.push(`${item}=${dataSearch[item]}`)
    }
    let dataInQuery = `?${queryTab.join("&")}`

    const url = `https://api-rest.alphabody.fr:2053/sheet${dataInQuery}`;
    try {
        const getRow = await axios({
            method: 'get',
            url: url
        })
        if (getRow) {
            return getRow.data
        } else {
            return "N/A"
        }
    } catch (error) {
        return "N/A"
    }
}
////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
async function modifSheets(dataModifs) {
    const url = `https://api-rest.alphabody.fr:2053/sheet`;
    try {
        const modifRow = await axios({
            method: 'post',
            url: url,
            Accept: 'application/json',
            data: dataModifs
        })
        if (modifRow) {
            return modifRow.data
        } else {
            return "ECHEC"
        }
    } catch (error) {
        return "ECHEC"
    }
}
////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
async function kajabi(dataKajabi) {
    const url = `https://api-rest.alphabody.fr:2053/kajabi`;
    try {
        const kajabi = await axios({
            method: 'post',
            url: url,
            Accept: 'application/json',
            data: dataKajabi
        })
        if (kajabi) {
            return kajabi.data
        } else {
            return "ECHEC"
        }
    } catch (error) {
        return "ECHEC"
    }
}
////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
async function getActiveCampaignContact(dataGetAc) {

    //transformation de la data en query
    let queryTab = []
    for (let item in dataGetAc) {
        queryTab.push(`${item}=${dataGetAc[item]}`)
    }
    let dataInQuery = `?${queryTab.join("&")}`

    const url = `https://api-rest.alphabody.fr:2053/active-campaign/contact${dataInQuery}`;
    try {
        const ActiveCampaignContact = await axios({
            method: 'get',
            url: url
        })
        if (ActiveCampaignContact) {
            return ActiveCampaignContact.data.contactId
        } else {
            return "ECHEC"
        }
    } catch (error) {
        return "ECHEC"
    }
}
////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
async function addActiveCampaigntag(dataTagAc) {
    const url = `https://api-rest.alphabody.fr:2053/active-campaign/tag`;
    try {
        const ActiveCampaignTag = await axios({
            method: 'post',
            url: url,
            Accept: 'application/json',
            data: dataTagAc
        })
        if (ActiveCampaignTag) {
            return ActiveCampaignTag.data
        } else {
            return "ECHEC"
        }
    } catch (error) {
        return "ECHEC"
    }
}
////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
async function updateCustomer(dataCustomer) {
    const urlBdd = `${configUrl}/graphQl`

    try {
        const update = await axios({
            method: 'post',
            url: urlBdd,
            data: {
                query: ` mutation updateCustomer (
                    $id: Int!, 
                    $product_id: Int
                    ){      
                  updateCustomer(
                    id: $id ,
                    product_id: $product_id
                    ){
                    id
                  }
                }`,
                variables: {
                    "id": parseInt(dataCustomer.id),
                    "product_id": parseInt(dataCustomer.product_id)
                }
            }
        })
        if (update) {
            return update.data.data.updateCustomer
        } else {
            return "ECHEC"
        }
    } catch (error) {
        return `ECHEC -${error}`
    }
}
////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
async function changeProductStripe(dataStripe) {
    const url = `${configUrl}/stripe/changeProduct`
    try {
        const stripe = await axios({
            method: 'post',
            url: url,
            Accept: 'application/json',
            data: dataStripe
        })
        if (stripe.data.statusCode === 200) {
            return ({
                status: "OK",
                amount: stripe.data.price.unit_amount
            })
        } else {
            return "ECHEC"
        }
    } catch (error) {
        return "ECHEC"
    }
}
////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
async function sendMsg(datasendMsg) {
    const url = `https://api-rest.alphabody.fr:2053/telegram/text`;
    try {
        const send = await axios({
            method: 'post',
            url: url,
            Accept: 'application/json',
            data: datasendMsg
        })
        if (send) {
            return send.data
        } else {
            return "ECHEC"
        }
    } catch (error) {
        return "ECHEC"
    }
}