import axios from 'axios';

export const createCustomerStripePortal = async (id) => {

    const url = `${process.env.REACT_APP_URL_API_FACTURATION}/stripe/create-customer-portal-session?id=${String(id)}`

    const response = await axios({
        method: 'get',
        url: url,
    })

    if (response.status === 200) {
        return response.data
    }

}
