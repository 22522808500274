
// Composant permettant d'envoyer l'arret differé dans la table dynamo via l'api'

import configUrl from './configUrl'
import axios from 'axios';


export const SendArretDiff = async (data) => {

    const urlBdd = `${configUrl}/graphQl`

    const variables = {
        "date": data.date,
        "id": parseInt(data.props.customer.id, 10),
        "sendMsg": data.sendMsg,
        "infos": {
            "email": data.props.email,
            "customer": data.props.customer
        }
    }

    const insert = await axios({
        method: 'post',
        url: urlBdd,

        data: {
            query: `
            mutation insertArretDiffDynamo (
                $date: String!, 
                $id: Int!, 
                $sendMsg: Boolean!,
                $infos: JSON){ 
                       
                 insertArretDiffDynamo(
                   date: $date,
                   id:$id,
                   sendMsg:$sendMsg,
                   infos: $infos )
               }
                  `,
            variables: variables
        }

    })
    if (insert) {
        return insert.data.data.insertArretDiffDynamo
    } else {
        return null
    }
}
