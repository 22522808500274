/*eslint no-unused-vars: "off"*/
import React from 'react';
import { Button } from 'primereact/button';
import { Dropdown } from 'primereact/dropdown';
import { ProgressSpinner } from 'primereact/progressspinner';
import { InputTextarea } from 'primereact/inputtextarea';
import { sendCoachTelegram } from '../../callApi/telegram';
import { Message } from 'primereact/message';
import { Calendar } from 'primereact/calendar';
import { addLocale } from 'primereact/api';

const MsgCoach = (props) => {

    const [state, setState] = React.useState({
        msgType: "",
        coachName: `${props.customer.coach_id.firstname} ${props.customer.coach_id.lastname}`,
        text: "",
        loader: false,
        result: "",
        error: false,
        infos: '',
        date: null
    })

    //creation de la traduction pour le calendrier
    addLocale('fr', {
        firstDayOfWeek: 1,
        dayNames: ['dimanche', 'lundi', 'mardi', 'mercredi', 'jeudi', 'vendredi', 'samedi'],
        dayNamesShort: ['dim', 'lun', 'mar', 'mer', 'jeu', 'vend', 'sam'],
        dayNamesMin: ['Dimanche', 'Lundi', 'Mardi', 'Mercredi', 'Jeudi', 'Vendredi', 'Samedi'],
        monthNames: ['janvier', 'fevrier', 'mars', 'avril', 'mai', 'juin', 'juillet', 'aout', 'septembre', 'octobre', 'novembre', 'decembre'],
        monthNamesShort: ['Ja', 'Fe', 'Ma', 'Av', 'Ma', 'Ju', 'Jui', 'Ao', 'Se', 'Oc', 'No', 'Dec'],
        today: 'Jour',
        clear: 'Effacer'
    });

    //convertion de la date
    const convertDate = (date) => {
        const newTime = new Intl.DateTimeFormat('fr-FR', { year: 'numeric', month: '2-digit', day: '2-digit' }).format(date)
        const splitDate = newTime.split("/")
        return `${splitDate[0]}/${splitDate[1]}/${splitDate[2]}`
    }

    const msgChange = (data) => {

        let text = `${props.customer.firstname} ${props.customer.lastname} - ${props.customer.email} - `

        const type = data.change === "msgType" ? data.value : state.msgType
        const infos = data.change === "infos" ? data.value : state.infos
        const date = data.change === "date" ? data.value : state.date

        switch (type) {
            case "nonPay":
                text = `${text} client en défaut de paiement, procédure de non paiement enclenchée`
                break;
            case "annul":
                text = `${text} Annulation le ${props.customer.end_date !== null ? convertDate(props.customer.end_date) : "XX/XX/XXX"}`
                break;
            case "arret":
                text = `${text} Arrêt le ${props.customer.end_date !== null ? convertDate(props.customer.end_date) : "XX/XX/XXX"}`
                break;
            case "defaut":
                text = `${text} Arrêt pour défaut le ${props.customer.end_date !== null ? convertDate(props.customer.end_date) : "XX/XX/XXX"}`
                break;
            case "diff":
                text = `${text} Arrêt différé programmé le ${props.customer.end_date !== null ? convertDate(props.customer.end_date) : "XX/XX/XXX"}`
                break;
            case "pause":
                text = `${text} suivi de coaching décalé au ${date !== null ? convertDate(date) : "XX/XX/XXX"} pour ${infos}`
                break;
            default:
                break;
        }
        setState({
            ...state,
            msgType: type,
            text: text,
            infos: infos,
            date: date
        })
    }
    const click = async (button) => {
        setState({
            ...state,
            loader: true
        })
        const telegram = await sendCoachTelegram({
            text: state.text,
            coach_id: props.customer.coach_id.id
        })
        if (telegram.statusCode === 200) {
            setState({
                ...state,
                error: false,
                result: "Envoyé avec succes",
                loader: false
            })
        } else {
            setState({
                ...state,
                error: true,
                result: "Erreur d'envoi",
                loader: false
            })
        }
    }

    return (

        <div>
            Type de message:
            <Dropdown
                className="m-1"
                value={state.msgType}
                options={[
                    { label: "Défaut de paiement", value: "nonPay" },
                    { label: "Annulation 14 jours", value: "annul" },
                    { label: "Arrêt simple", value: "arret" },
                    { label: "Arrêt pour Défaut", value: "defaut" },
                    { label: "Arrêt différé", value: "diff" },
                    { label: "Pause programme", value: "pause" }
                ]}
                onChange={(e) => msgChange({
                    change: "msgType",
                    value: e.target.value
                })}
                optionLabel="label"
                placeholder="" />
            {state.msgType &&
                <React.Fragment>
                    {
                        state.msgType === "pause" &&
                        <div>
                            Raison:
                            <Dropdown
                                className="m-1"
                                value={state.infos}
                                options={[
                                    { label: "certificat médical validé", value: "certificat médical validé" }
                                ]}
                                onChange={(e) => msgChange({
                                    change: "infos",
                                    value: e.target.value
                                })}
                                optionLabel="label"
                                placeholder="" />
                            <Calendar dateFormat="dd/mm/yy" id="icon" locale="fr" value={state.date} onChange={(e) => msgChange({
                                change: "date",
                                value: e.target.value
                            })}
                                showIcon />
                        </div>
                    }
                    <div>
                        <InputTextarea
                            rows={2} cols={100}
                            value={state.text} onChange={(e) => setState({ ...state, text: e.target.value })}
                            autoResize />
                    </div>
                    {
                        state.loader ?
                            <ProgressSpinner className="p-1 text-sm" style={{ width: '40px', height: '40px' }} strokeWidth="4" animationDuration="1.5s" />
                            :
                            <div>
                                <Button
                                    onClick={() => click(state.msgType)}
                                    label={`Envoi du message à ${state.coachName}`}
                                />
                            </div>
                    }
                    {
                        state.result !== "" &&
                        <Message className="text-sm m-0 m-2 p-2" icon="text-xl pi pi-times" severity={state.error ? "warn" : "success"} text={state.result} />
                    }
                </React.Fragment>
            }
        </div>

    )
}


export default MsgCoach;