import axios from "axios"
// Fonction envoyant la requete de remboursement sous 14 jours a l'API rest

export const sendAPIremboursement14jours = async (email) => {

    const url = `https://api-rest.alphabody.fr:2053/process/annulation`

    const result = await axios({
        method: 'post',
        url: url,

        data: {
            "email": email
        }
    })

    if (result) {
        return result.data
    } else {
        return null
    }
}