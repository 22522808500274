
// Fonction envoyant la requete d'arret avec remboursement a l'API GraphQl 
import axios from 'axios'

export const sendAPIremboursementArret = async (data) => {

    const options = { year: 'numeric', month: 'numeric', day: 'numeric' }
    const day = new Date(data.date).toLocaleDateString('fr-FR', options)

    const url = `https://api-rest.alphabody.fr:2053/process/arret`

    const result = await axios({
        method: 'post',
        url: url,

        data: {
            email: data.email,
            amount: data.amount,
            type: data.stopType,
            date: day
        }
    })

    if (result) {
        return result.data
    } else {
        return null
    }

}