import axios from 'axios';

export const getInvoicesQuaderno = async (email) => {

    let idQuaderno = null

    let url = `https://api-facturation.alphabody.fr:2053/quaderno/getAllContacts?email=${String(email)}`

    const response = await axios({
        method: 'get',
        url: url,
    })

    if (response.status === 200) {

        //si pas de client existant
        if (response.data.length === 0) {
            return ({
                status: 400,
                message: "aucun client trouvé dans le systeme de facturation avec cette adresse mail"
            })
        }

        //parcours des multiples contacts possibles
        let tempInvoices = []
        for (const contact of response.data) {
            idQuaderno = contact.id

            url = `https://api-facturation.alphabody.fr:2053/quaderno/getAllInvoices?contact=${String(idQuaderno)}`

            const invoices = await axios({
                method: 'get',
                url: url,
            })

            if (invoices.status === 200) {
                if (invoices.data.length !== 0) {
                    tempInvoices.push(...invoices.data)
                }
            }
        }

        if (tempInvoices.length === 0) {
            return ({
                status: 400,
                message: "aucune facture trouvées"
            })
        }

        return ({
            status: 200,
            message: "",
            invoices: tempInvoices
        })
    }


}
