
// Composant permettant de supprimer le client de la liste Active Campaign

import configUrl from './configUrl'
import axios from 'axios';

export const stopActiveCampaign = async (props) => {
    console.log('props ActiveCampaign:', props)

    const url = `${configUrl}/activecampaign/removelistClient?email=${String(props.email)}`

    const init = {
        method: 'POST'
    }

    const response = await fetch(url, init)
        .then((response) => {
            //console.log('response:', response)
            //alert('supression du programme sur kajabi terminée')
            return response.json()
        }).catch((err) => {
            //alert('erreur dans la supression du programme sur kajabi:', err)
            console.log('erreur dans la supression du programme sur kajabi:', err)
        }
        )

    console.log('resultat suppression ActiveCampaign:', response)
    return response
}

export const getContactActiveCampaign = async (data) => {

    const url = `https://api-rest.alphabody.fr:2053/active-campaign/contact?email=${data.email}`

    const getContact = await axios({
        method: 'GET',
        url: url
    })

    if (getContact) {
        return getContact.data.contactId
    } else {
        return "N/A"
    }
}


