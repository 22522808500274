import React, { useState } from 'react';
import { Button } from 'primereact/button';
import { ProgressSpinner } from 'primereact/progressspinner';
import { Tag } from 'primereact/tag';
import { getInvoicesQuaderno } from '../../callApi/getInvoicesQuaderno'
import { Card } from 'primereact/card';

// Composant permettant de lancer les arret diférés
const Renewal = ({ email }) => {

    const [invoices, setInvoices] = React.useState({
        error: false,
        processing: true,
        tab: []
    })

    const getInvoices = async (email) => {
        const recupInvoices = await getInvoicesQuaderno(email)

        //si erreur
        if (recupInvoices.status === 400) {
            setInvoices({
                error: true,
                message: recupInvoices.message,
                processing: false,
                tab: []
            })
        } else {
            setInvoices({
                error: false,
                message: "",
                processing: false,
                tab: recupInvoices.invoices
            })
        }

    }

    React.useEffect(() => {
        getInvoices(email)
    }, [email])

    const click = (url) => {
        window.open(url)
    }

    //convertion de la date
    const convertDate = (date) => {
        const newTime = new Intl.DateTimeFormat('fr-FR', { year: 'numeric', month: '2-digit', day: '2-digit' }).format(date * 1000)
        const splitDate = newTime.split("/")
        return `${splitDate[0]}/${splitDate[1]}/${splitDate[2]}`
    }

    const spinner = <ProgressSpinner className="p-1 text-sm" style={{ width: '40px', height: '40px' }} fill="#ffffff" strokeWidth="10" animationDuration="1.5s" />

    return (
        <div >
            {
                invoices.processing &&
                <>
                    <h2>
                        Recherche en cours
                    </h2>
                    <div>
                        {spinner}
                    </div>
                </>
            }

            {
                <div className='flex justify-content-center align-items-center flex-wrap'>
                    {
                        (!invoices.processing && !invoices.error) ?

                            invoices.tab.map((el) =>

                                <Card className='w-12 md:w-5 m-1'
                                    key={`card-${el.id}`}>

                                    <div className="m-1 flex justify-content-center gap-4"
                                        key={`title-${el.id}`}>
                                        <div className="m-1" key={`div-1-${el.id}`}>
                                            <strong key={`strong-${el.id}`}> Numéro: </strong>{el.number}
                                        </div>
                                        <div className="m-1" key={`div-2-${el.id}`}>
                                            <strong key={`strong-1-${el.id}`}> Date: </strong>{convertDate(el.created_at)}
                                        </div>
                                        <div className="m-1" key={`div-3-${el.id}`}>
                                            <strong key={`strong-3-${el.id}`}> Montant: </strong>{el.total_cents / 100} {el.currency}
                                        </div>
                                    </div>

                                    <div key={`infos-${el.id}`} className="justify-content-start text-left">
                                        <h4 className="m-0">
                                            Produits:
                                        </h4>
                                        <ul>
                                            {el.items.map((item) =>
                                                <li key={`item-${item.id}`}>
                                                    {item.description}
                                                </li>
                                            )}
                                        </ul>
                                    </div>

                                    <div className="m-1 flex justify-content-end"
                                        key={`btndiv-${el.id}`}>
                                        < Button
                                            key={`btn-${el.id}`}
                                            className="m-1 p-button-secondary pi pi-file-pdf "
                                            onClick={(e) => click(el.pdf)}
                                            label={` Télécharger`}
                                        />
                                    </div>
                                </Card>

                            )

                            :
                            <>
                                {invoices.error &&
                                    <Tag className="m-1 text-sm" severity="danger" value={invoices.message} ></Tag>
                                }
                            </>
                    }
                </div>
            }
        </div >

    )
}

export default Renewal;