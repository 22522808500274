/*eslint no-unused-vars: "off"*/
import React from 'react';

import { sendAPIremboursementArret } from '../../callApi/sendApiRembourssementArret';

import { Tag } from 'primereact/tag';
import { Calendar } from 'primereact/calendar';
import { addLocale } from 'primereact/api';
import { Dropdown } from 'primereact/dropdown';
import { Button } from 'primereact/button';
import Loader from "react-loader-spinner";

// Fonction de verification du montant entré par l'utilisateur
const verificationNumber_amount = (amount) => {
    const num = ['0', '1', '2', '3', '4', '5', '6', '7', '8', '9', '.'];
    let is_valid = true;
    let nb_points = 0;
    String(amount).split("").forEach(n => { is_valid = is_valid && num.includes(n); if (n === '.') { nb_points = nb_points + 1 } })
    if (is_valid && nb_points < 2) {
        return true;
    } else {
        return false
    }
}
const verificationCentimes = (amount) => { return 100 * amount - Math.floor(100 * amount) < 0.01 }

// Composant contenant le module d'arret avec remboursement 
// Contient une description (texte), un input (montant du remboursement) et un bouton lancant l'arret avec remboursement via une requete a l'API GraphQL
const RemboursementArret = (props) => {
    //console.log('props:', props)

    //creation de la traduction pour le calendrier
    addLocale('fr', {
        firstDayOfWeek: 1,
        dayNames: ['dimanche', 'lundi', 'mardi', 'mercredi', 'jeudi', 'vendredi', 'samedi'],
        dayNamesShort: ['dim', 'lun', 'mar', 'mer', 'jeu', 'vend', 'sam'],
        dayNamesMin: ['Dimanche', 'Lundi', 'Mardi', 'Mercredi', 'Jeudi', 'Vendredi', 'Samedi'],
        monthNames: ['janvier', 'fevrier', 'mars', 'avril', 'mai', 'juin', 'juillet', 'aout', 'septembre', 'octobre', 'novembre', 'decembre'],
        monthNamesShort: ['Ja', 'Fe', 'Ma', 'Av', 'Ma', 'Ju', 'Jui', 'Ao', 'Se', 'Oc', 'No', 'Dec'],
        today: 'Jour',
        clear: 'Effacer'
    });

    let [state, setState] = React.useState({
        email: props.email,
        amount: 0,
        callbackReload: props.callbackReload,
        isButtonEnable: true,
        isSpinnerEnable: false,
        checked: false,
        stopType: "Arrêt"
    });

    let [date, setDate] = React.useState({
        value: new Date(),
        display: new Date().toLocaleDateString(`fr-FR`, { year: 'numeric', month: '2-digit', day: '2-digit' })
    })

    let [error, setError] = React.useState(
        {
            success: null,
            message: []
        }
    )

    //transformation de la date au bon format et envoi dans le state du composant apres verif si supperieure a aujourd'hui
    const changeDate = (value) => {

        const selectDate = new Date(value)
        const today = new Date()

        if (today >= selectDate) {
            setError({ success: null, message: '' })
            const resDate = selectDate.toLocaleDateString(`fr-FR`, { year: 'numeric', month: '2-digit', day: '2-digit' })
            setDate({
                value: selectDate,
                display: selectDate.toLocaleDateString(`fr-FR`, { year: 'numeric', month: '2-digit', day: '2-digit' })
            })
            setState({ ...state, isButtonEnable: true })
        } else {
            setDate({
                value: new Date(),
                display: new Date().toLocaleDateString(`fr-FR`, { year: 'numeric', month: '2-digit', day: '2-digit' })
            })
            setError({ success: false, message: `merci de renseigner une date antérieure ou égale a aujourd'hui` })
            setState({ ...state, isButtonEnable: false })
        }
    }

    React.useEffect(() => {
        setState({ ...state, email: props.email })
        // eslint-disable-next-line
    }, [props.email]);

    // Click sur le bouton
    const click = async () => {

        // desactivation du bouton
        setState({ ...state, isButtonEnable: false, isSpinnerEnable: true })

        // Verification de la somme entré par l'utilisateur
        if (verificationNumber_amount(state.amount) && verificationCentimes(parseFloat(state.amount))) {
            setError({
                success: null,
                message: []
            })

            // requete a l'API pour le rembourssement
            const reponse = await sendAPIremboursementArret({ email: state.email, amount: state.amount, date: date.value, stopType: state.stopType })
            // reponse de la requete
            console.log('reponse:', reponse)
            setError({
                success: reponse.status,
                message: reponse.message
            })
        }
        else {
            setError({ success: false, message: `${`Mauvaise somme`}` }) //alert("Mauvaise somme" + state.success)
        }
        // reactivation du bouton
        setState({ ...state, isButtonEnable: true, isSpinnerEnable: false })

    }

    return (
        <form onSubmit={(e) => { e.preventDefault() }} className="div_border_bottom">

            <div>
                Somme a rembourser (facultatif):
                <input type="number" name="name" value={state.amount} onChange={(e) => setState({ ...state, amount: e.target.value })} onSubmit={click} disabled={!state.isButtonEnable} />
            </div>
            <div className="m-2">
                Date:
                <Calendar className="m-1" dateFormat="dd/mm/yy" id="icon" locale="fr" value={date.value} onChange={(e) => changeDate(e.value)} showIcon />
            </div>
            <div>
                Type d'arret:
                <Dropdown
                    className="m-1"
                    value={state.stopType}
                    options={[
                        { label: "Arrêt", value: "Arrêt" },
                        { label: "Défaut", value: "Défaut" }
                    ]}
                    onChange={(e) => setState({ ...state, stopType: e.target.value })}
                    optionLabel="label"
                    placeholder="Type d'arret" />
            </div>

            {
                state.isButtonEnable &&
                <button type="button" onClick={click} >{`Arret ${state.amount <= 0 ? `sans remboursement` : `avec ${state.amount} € remboursés`} le ${date.display}`}  </button>
            }

            {state.isSpinnerEnable ? <Loader type="Rings">Load</Loader> : null}

            {
                error.success !== null &&
                error.message.map((item) =>
                    <div>
                        <Tag className="m-1 text-xl" severity={item.includes("Erreur") ? "danger" : "success"} value={item} key={item} />
                    </div>
                )
            }
        </form >

    )
}


export default RemboursementArret;