import React, { useState } from 'react';

import { vipToForm } from '../../callApi/vipToForm';

import { InputText } from 'primereact/inputtext';
import { ProgressSpinner } from 'primereact/progressspinner';
import { Button } from 'primereact/button';
import { Message } from 'primereact/message';
import { SelectButton } from 'primereact/selectbutton';
import { Tag } from 'primereact/tag';

// Composant permettant de lancer les arret diférés
const VipToForm = (props) => {

    let [state, setState] = useState({
        btn: false,
        spninner: false,
        error: false,
        message: "",
        author: "",
        period: "",
        payMethod: ""
    })

    //verif des champs
    React.useEffect(() => {
        if (state.period !== "" && state.payMethod !== "" && state.author !== "") {
            setState({
                ...state,
                btn: true
            })
        } else {
            setState({
                ...state,
                btn: false
            })
        }

    }, [state.payMethod, state.period, state.author])

    //appel de l'enregistrement au click
    const click = async () => {

        setState({
            ...state,
            btn: false,
            spninner: true
        })

        const vipToFlag = await vipToForm({
            customer: props.customer,
            subs: props.subs,
            infos: { ...state }
        })
        //analyse du resultat
        if (vipToFlag.status === "OK") {
            setState({
                ...state,
                btn: false,
                spninner: false,
                error: false,
                message: vipToFlag.message
            })
        } else {
            setState({
                ...state,
                btn: false,
                spninner: false,
                error: true,
                message: vipToFlag.message
            })
        }

    }

    return (
        <React.Fragment>
            <Tag icon="text-xl pi pi-info-circle " className="m-1 text-xl" severity="info" value={`Fonctionnalité nouvelle - merci de vérifier le fonctionnemnt`}></Tag>
            <div class="grid">
                <div className='col text-right'>
                    <h5 className=''>Ton Nom:</h5>
                    <h5 className=''>Périodicité du nouveau programme:</h5>
                    <h5 className=''>Moyen de paiement</h5>
                </div>
                <div className='col text-left'>
                    <InputText
                        className='m-1'
                        value={state.author}
                        onChange={(e) => setState({ ...state, author: e.target.value })}
                    />
                    <SelectButton
                        className='m-1'
                        optionLabel="name"
                        value={state.period}
                        options={[{ name: "Mensuelle", value: "Mensuel" }, { name: "Annuelle", value: "Annuel" }]}
                        onChange={(e) => setState({ ...state, period: e.value })}
                    />
                    <SelectButton
                        className='m-1'
                        optionLabel="name"
                        value={state.payMethod}
                        options={[{ name: "Stripe", value: "Stripe" }, { name: "Paypal", value: "Paypal" }, { name: "RIB", value: "RIB" }]}
                        onChange={(e) => setState({ ...state, payMethod: e.value })}
                    />
                </div>
            </div>
            <div>

                {
                    state.btn &&
                    <div className='flex-column'>
                        <div>
                            <Message className="text-sm m-0 m-2 p-2" icon="text-xl pi pi-times" severity="info" text={`Modification du programme VIP de ${props.customer.firstname} ${props.customer.lastname} en programme Formation ${parseInt(props.customer.product_id.id) === 3 ? "Homme" : "Femme"} - ${state.period} le client a déja réglé via ${state.payMethod}`} />
                        </div>
                        <Button onClick={(e) => click()}>Convertir en client Formation</Button>
                    </div>
                }
                {
                    state.spninner &&
                    <ProgressSpinner className="p-1 text-sm" style={{ width: '40px', height: '40px' }} strokeWidth="4" animationDuration="1.5s" />
                }
                {
                    state.message !== "" &&
                    <div>
                        <Message className="text-sm m-0 m-2 p-2" icon="text-xl pi pi-times" severity={state.error ? "warn" : "info"} text={
                            state.message.split("\n").map((i, key) => {
                                return <div key={key}>{i}</div>
                            })} />
                    </div>
                }
            </div >
        </React.Fragment >
    )
}

export default VipToForm;