// Fonction pour envoyer une requete a l'API (recuperer les subscriptions du client avec l'email)

import configUrl from './configUrl'

export const getSubscriptions = async (props) => {

    const url = `${configUrl}/subscription/stripe?email=${String(props.email)}`

    const init = { method: 'GET' }
    const response = await fetch(url, init).then((response) => {
        if (!response.ok) {
            throw new Error("HTTP status " + response.status);
        }
        return response.json();
    })
    return response.response

}