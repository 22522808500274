

import * as React from "react";

import { getCustomer } from "../../../callApi/getCustomerById";
import { updateCustomerById } from "../../../callApi/updateCustomerById";
import { getCoachs } from "../../../callApi/getCoachs";
import { getSellers } from "../../../callApi/getSellers";

import { Button } from 'primereact/button';
import { confirmPopup } from 'primereact/confirmpopup';
import { Toast } from 'primereact/toast';
import { Dropdown } from 'primereact/dropdown';
import { Message } from 'primereact/message';
import { InputText } from 'primereact/inputtext';
import { Card } from "primereact/card";


function ModifCustomer(props) {
  const params = new URLSearchParams(window.location.search)

  // definition du state
  let [state, setState] = React.useState({
    email: `${!!props.email ? props.email : params.get('email')}`,
    refresh: false,
    confirmVisible: false,
    customer: null,
  })
  const toast = React.useRef(null);

  //recup et enregistrement dans le state des infos
  const recupInfos = async () => {
    const cus = await getCustomer(state.email)
    const listCoachs = await getCoachs()
    for (let id in listCoachs) {
      listCoachs[id] = {
        ...listCoachs[id],
        name: `${listCoachs[id].firstname} ${listCoachs[id].lastname}`
      }
    }
    const listSellers = await getSellers()
    for (let id in listSellers) {
      listSellers[id] = {
        ...listSellers[id],
        name: `${listSellers[id].firstname} ${listSellers[id].lastname}`
      }
    }
    if (cus) {
      setState({
        ...state,

        id: cus.id,
        firstname: cus.firstname,
        lastname: cus.lastname,
        email: cus.email,
        tel: cus.tel,
        alphaPoint: cus.alphaPoint,
        created_at: convertDate(cus.created_at),
        end_date: convertDate(cus.end_date),
        activ: cus.activ,
        annulation: cus.annulation,
        coach_id: `${cus.coach_id ? cus.coach_id.id : ''}`,
        seller_id: `${cus.seller_id ? cus.seller_id.id : ''}`,
        dateofbirth: convertDate(cus.dateofbirth),

        coachsList: listCoachs,
        sellersList: listSellers,

        customer: cus
      })
    }
  }

  //recup des infos au refresh et a l'ouverture
  React.useEffect(() => {
    recupInfos()
  }, [state.refresh])

  //convertion de la date pour les input
  const convertDate = (date) => {
    const newTime = new Intl.DateTimeFormat('fr-FR', { year: 'numeric', month: '2-digit', day: '2-digit' }).format(date)
    const splitDate = newTime.split("/")
    return `${splitDate[2]}-${splitDate[1]}-${splitDate[0]}`
  }

  //envoi en bdd des modifs
  const modif = async () => {

    const result = await updateCustomerById(state)

    if (result) {
      setState({
        ...state,
        refresh: true
      })
    }

    toast.current.show({ severity: 'info', summary: 'Confirmé', life: 3000 });
  }
  const reject = () => {
    toast.current.show({ severity: 'warn', summary: 'Annulé', life: 3000 });
  }

  const confirm = (target) => {
    confirmPopup({
      target: target,
      message: 'Veux tu vraiment modifier les infos client?',
      icon: 'pi pi-exclamation-triangle',
      acceptLabel: "OUI",
      rejectLabel: "NON",
      accept: () => modif(),
      reject: () => reject()
    });
  }

  return (

    <Card className="m-1 p-2">
      {
        !!state.customer &&

        <div>
          <Toast ref={toast} />
          <h1>Client</h1>
          <Message icon="pi pi-info"
            severity="error"
            text={`Attention les modifications apportées ici ne changent pas les fichiers, seulement la base de donnée, merci de modifier les fichiers a la main`}
          />

          <div className="m-2">
            <label className="m-1">Prénom:</label>
            <InputText id="in" value={state.firstname} onChange={(e) => setState({ ...state, firstname: e.target.value })} />
            <label className="m-1">Nom:</label>
            <InputText id="in" value={state.lastname} onChange={(e) => setState({ ...state, lastname: e.target.value })} />
          </div>

          <div className="m-2">
            <label className="m-1">Tel:</label>
            <InputText id="in" value={state.tel} onChange={(e) => setState({ ...state, tel: e.target.value })} />
            <label className="m-1">Email:</label>
            {state.email}
          </div>

          <div className="m-2">
            <label className="m-1">Coach:</label>
            <Dropdown filter optionLabel="name" optionValue="id" value={state.coach_id} options={state.coachsList} onChange={(e) => setState({ ...state, coach_id: e.value })} />
          </div>

          <div className="m-2">
            <label className="m-1">Vendeur:</label>
            <Dropdown filter optionLabel="name" optionValue="id" value={state.seller_id} options={state.sellersList} onChange={(e) => setState({ ...state, seller_id: e.value })} />
          </div>

          <div>
            <label className="m-1">Anniversaire le:</label>
            <InputText id="in" type="date" value={state.dateofbirth} onChange={(e) => setState({ ...state, dateofbirth: e.target.value })} />
          </div>

          <div>
            <label className="m-1">Actif ?</label>
            <Dropdown optionLabel="name" optionValue="value" value={state.activ} options={[{ name: "OUI", value: true }, { name: "NON", value: false }]} onChange={(e) => setState({ ...state, activ: e.value, end_date: e.value ? null : state.end_date })} />
            <label className="m-1">Annulation ?</label>
            <Dropdown optionLabel="name" optionValue="value" value={state.annulation} options={[{ name: "OUI", value: true }, { name: "NON", value: false }]} onChange={(e) => setState({ ...state, annulation: e.value })} />
          </div>

          <div className="m-2">
            <label className="m-1">Entrée le:</label>
            <InputText id="in" type="date" value={state.created_at} onChange={(e) => setState({ ...state, created_at: e.target.value })} />
            {
              !state.activ &&
              <div className="m-2">
                <label className="m-1">Sortie le:</label>
                <InputText id="in" type="date" value={state.end_date} onChange={(e) => setState({ ...state, end_date: e.target.value })} />
              </div>
            }
          </div>


          <div className="m-2">
            <Button
              onClick={(e) => confirm(e.target)}
            >Modifier</Button>
          </div>
        </div>
      }


    </Card>
  );
}

export default ModifCustomer;
