/*eslint no-unused-vars: "off"*/
import React from 'react';
import { Button } from 'primereact/button';
import { Card } from 'primereact/card';
import { ProgressSpinner } from 'primereact/progressspinner';
import { Tag } from 'primereact/tag';
import { listAllCustomerByMailWithSubscription } from '../../callApi/listCustomerStripeWithSub';
import { createCustomerStripePortal } from '../../callApi/createCustomerStripePortal'
import { CopyToClipboard } from 'react-copy-to-clipboard';
import { Message } from 'primereact/message';

const StripeList = (props) => {

    const [list, setList] = React.useState(null)
    const [url, setUrl] = React.useState(null)
    const [copy, setCopy] = React.useState(false)

    React.useEffect(() => {
        get()
    }, [props.email])

    const get = async () => {
        const res = await listAllCustomerByMailWithSubscription(props.email)
        //suppression des customer sans sub
        let tempList = []
        for (const cus of res) {
            if (cus.product.length > 0) {
                tempList.push(cus)
            }
        }
        setList(tempList)
    }

    //creation d'une session customer stripe pour changement cb
    const createCustomerStripePortalSession = async (cus_id) => {
        const portal = await createCustomerStripePortal(cus_id)
        setUrl(portal.url)
    }
    //go portal
    const goPortal = async (cus_id) => {
        window.open(url)
    }

    return (
        <>
            {
                url ?
                    <Card>
                        <Message
                            icon="pi pi-info"
                            severity="warn"
                            text={`Les sessions du portail sont temporaires. Les nouvelles sessions de portail expirent après une période de cinq minutes. Si un client l'utilise pendant cette période, la session expire dans l'heure qui suit l'activité la plus récente. Chaque session de portail peut durer jusqu'à deux heures avant d'expirer.`}
                        />
                        <h4 className='overflow-hidden text-overflow-ellipsis'>{url}</h4>
                        <div>
                            <CopyToClipboard text={url}
                                onCopy={() => setCopy(true)}>
                                <Button
                                    className={copy ? " m-1 p-button-success" : "m-1 p-button-info"} >
                                    <i className={copy ? "m-1 pi pi-check-square" : "m-1 pi pi-copy"} />
                                    {copy ? "Adresse copiée" : "Copier l'adresse"}
                                </Button>
                            </CopyToClipboard>

                            <Button
                                className='m-1 p-button-info'
                                onClick={() => goPortal()} >
                                <i className="m-1 pi pi-credit-card" />
                                aller au changement cb
                            </Button>
                        </div>
                    </Card>
                    :
                    list === null ?
                        <ProgressSpinner className="p-1 text-sm" style={{ width: '40px', height: '40px' }} fill="#ffffff" strokeWidth="10" animationDuration="1.5s" />
                        :
                        list.length !== 0 ?
                            list.map(cus =>
                                <Card className='m-1'>
                                    <div className='m-1'>
                                        <h2>client:</h2>
                                        <strong>Nom: </strong>{cus.name} <strong>Id stripe: </strong>({cus.id})
                                    </div>
                                    <div className='m-1'>
                                        <h2>produits:</h2>
                                        {cus.product.map(prod =>
                                            <div className='m-1 overflow-hidden text-overflow-ellipsis'>
                                                {prod.product}
                                                <Tag className='text-sm m-1' value={prod.status} severity={prod.status === 'active' ? "success" : "warn"} />
                                            </div>
                                        )}
                                    </div>
                                    <Button
                                        className='p-button-info'
                                        onClick={() => createCustomerStripePortalSession(cus.id)} >
                                        <i className="m-1 pi pi-credit-card" />
                                        session temporaire changement cb
                                    </Button>
                                </Card>
                            )
                            :
                            <Tag className='text-sm m-1' value="Aucun client avec abonnement" severity="info" />
            }
        </>
    )
}


export default StripeList;