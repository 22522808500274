
// Composant permettant de recup le ou les arret differé dans la table dynamo via l'api'

import configUrl from './configUrl'
import axios from 'axios';

export const getArretDiff = async (email) => {

    const urlBdd = `${configUrl}/graphQl`

    const variables = {
        "email": `${!!email ? email : ""}`,
    }

    const get = await axios({
        method: 'post',
        url: urlBdd,

        data: {
            query: `
            query getArretDiffDynamo($email: String){
                getArretDiffDynamo(email: $email){
                    id,
                    date,
                    infos
                }
            }
                  `,
            variables: variables
        }

    })
    if (get) {
        return get.data.data.getArretDiffDynamo
    } else {
        return null
    }
}
