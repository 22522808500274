
// Composant permettant de supprl'arret differé dans la table dynamo via l'api'

import configUrl from './configUrl'
import axios from 'axios';


export const delArretDiff = async (data) => {

    const urlBdd = `${configUrl}/graphQl`

    const variables = {
        "date": data.date,
        "id": data.id
    }

    console.log('props delArretDiff:', variables)

    const del = await axios({
        method: 'post',
        url: urlBdd,

        data: {
            query: `
            mutation delArretDiffDynamo($date: String!, $id:String!){
                delArretDiffDynamo(date:$date, id:$id){
                     id
                 }
             }
            `,
            variables: variables
        }

    })
    if (del) {
        return del.data.data.delArretDiffDynamo
    } else {
        return null
    }
}
