import React from 'react';
import { Card } from 'primereact/card';
import { Tag } from 'primereact/tag';
import { Message } from 'primereact/message';
import { ProgressSpinner } from 'primereact/progressspinner';

const VerifRecup = (props) => {
    //recuperation du type de produit
    let productType
    try {
        productType = props.state.customer.product_id.name.includes("VIP") ? "VIP" : "FORM"
    } catch (error) {
        productType = ""
    }
    //recuperation du moyen de paiement
    let processor
    try {
        processor = props.state.customer.sale.processor
    } catch (error) {
        processor = ""
    }

    //tableau de resultats de la verif
    //verification de bases communes a tous
    let verifTab = [
        { name: "BDD", result: null },
        { name: "Active Campaign", result: null },
        { name: "Reporting", result: null },
        { name: "Acceptations", result: null },
    ]
    //ajout des verification spécifiques
    if (processor.toLowerCase() === "stripe") {
        verifTab.push({ name: "Stripe", result: null })
    }
    if (processor.toLowerCase() === "alma") {
        verifTab.push({ name: "Alma", result: null })
    }
    if (productType === "VIP") {
        verifTab.push({ name: "Test New Vip", result: null })
    }

    //traitement des vérifications
    const verification = (item) => {

        switch (item) {
            //verification bdd
            case "BDD":
                if (props.state.customer !== null) {
                    try {
                        if (!!props.state.customer &&
                            !!props.state.customer.sale &&
                            !!props.state.customer.sale.processor_cus_id &&
                            (props.state.customer.sale.processor.toLowerCase() === "stripe" ||
                                props.state.customer.sale.processor.toLowerCase() === "alma" ||
                                props.state.customer.sale.processor === "RIB")
                        ) {
                            return true
                        } else {
                            return false
                        }
                    } catch (err) {
                        return false
                    }
                } else {
                    return null
                }

            //verification abbonement stripe
            case "Stripe":
                if (props.state.subs !== null) {
                    try {
                        if (!!props.state.subs && !props.state.subs.error && props.state.subs.length >= 1) {
                            return true
                        } else {
                            return false
                        }
                    } catch (err) {
                        return false
                    }
                } else {
                    return null
                }

            //verification alma
            case "Alma":
                if (props.state.sale !== null) {
                    try {
                        if (!!props.state.customer.sale.detail) {
                            return true
                        } else {
                            return false
                        }
                    } catch (err) {
                        return false
                    }
                } else {
                    return null
                }

            //verification activeCampaign
            case "Active Campaign":
                if (props.state.activeCampaignId !== null) {
                    try {
                        if (props.state.activeCampaignId !== "N/A") {
                            return true
                        } else {
                            return false
                        }
                    } catch (err) {
                        return false
                    }
                } else {
                    return null
                }

            //verification de la ligne dans reporting
            case "Reporting":
                if (props.state.rowReporting !== null) {
                    try {
                        if (props.state.rowReporting.row !== null && props.state.rowReporting.row !== "N/A") {
                            return true
                        } else {
                            return false
                        }
                    } catch (err) {
                        return false
                    }
                } else {
                    return null
                }

            //verification de la ligne dans test new vip
            case "Test New Vip":
                if (props.state.rowTestNewVip !== null) {
                    try {
                        if (props.state.rowTestNewVip.row !== null && props.state.rowTestNewVip.row !== "N/A") {
                            return true
                        } else {
                            return false
                        }
                    } catch (err) {
                        return false
                    }
                } else {
                    return null
                }

            //verification de la ligne dans suivi des acceptations
            case "Acceptations":
                if (props.state.rowAcceptations !== null) {
                    try {
                        if (props.state.rowAcceptations.row !== null && props.state.rowAcceptations.row !== "N/A") {
                            return true
                        } else {
                            return false
                        }
                    } catch (err) {
                        return false
                    }
                } else {
                    return null
                }

            default:
                return null
        }

    }

    //lancements des verifications
    for (let verif in verifTab) {
        const result = verification(verifTab[verif].name)
        verifTab[verif] = { ...verifTab[verif], result: result }
        //mise en erreur de la page si erreur sur une données
        if (result === false) {
            props.setErrorData(true)
        }
    }

    const spinner = <ProgressSpinner className="p-1 text-sm" style={{ width: '40px', height: '40px' }} fill="#ffffff" strokeWidth="10" animationDuration="1.5s" />

    return (
        <Card className='m-1'>
            {
                props.state.email && props.state.search !== false &&
                < React.Fragment >

                    {
                        verifTab.map(item =>

                            item.result === null ?
                                <Tag className="m-1 text-sm" severity="info" >{spinner} {item.name}</Tag>
                                :
                                <Tag className="m-1 text-sm" severity={item.result ? "success" : "danger"} value={item.name} ></Tag>
                        )
                    }
                </React.Fragment>
            }

            {
                props.state.message !== null &&
                <div>
                    <Message className="text-sm m-0 m-2 p-2" icon="text-xl pi pi-times" severity={!props.state.search ? "warn" : "info"} text={props.state.message} />
                </div>
            }

        </Card >
    )
}


export default VerifRecup;