
import url from "./configUrl"
import axios from 'axios';

const urlBdd = `${url}/graphQl`

export const updateCustomerById = async (data) => {
  //updateCustomerById
  const customer = await axios({
    method: 'post',
    url: urlBdd,

    data: {
      query: `
      mutation updateCustomer (
        $id: Int!,
        $firstname: String ,
        $lastname:  String,
        $tel:  String,
        $email: String ,
        $created_at: String ,
        $dateofbirth: String ,
        $end_date: String,
        $activ: Boolean,
        $annulation: Boolean,
        $coach_id: Int,
        $seller_id: Int,
        ){
            
      updateCustomer(
        id: $id ,
        firstname: $firstname ,
        lastname: $lastname ,
        tel: $tel ,
        email: $email ,
        created_at: $created_at ,
        dateofbirth: $dateofbirth ,
        end_date: $end_date ,
        activ: $activ,
        annulation: $annulation,
        coach_id: $coach_id ,
        seller_id: $seller_id,
        ){
        id
      }
    }
              `,
      variables: {
        "id": parseInt(data.id),
        "firstname": data.firstname,
        "lastname": data.lastname,
        "tel": data.tel,
        "email": data.email,
        "created_at": data.created_at,
        "dateofbirth": data.dateofbirth,
        "activ": data.activ,
        "annulation": data.annulation,
        "end_date": data.end_date,
        "coach_id": parseInt(data.coach_id),
        "seller_id": parseInt(data.seller_id)
      }
    }

  })
  if (customer) {
    return customer.data.data.updateCustomerById
  } else {
    return null
  }
}
