

import * as React from "react";
import { Button } from 'primereact/button';
import { confirmPopup } from 'primereact/confirmpopup';
import { Toast } from 'primereact/toast';
import { Checkbox } from 'primereact/checkbox';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { InputText } from 'primereact/inputtext';

import { getArretDiff } from '../../../callApi/getArretDiff'
import { delArretDiff } from '../../../callApi/delArretDiff'
import { cancelSubsEndPeriod } from '../../../callApi/scheduleSubsStripe'
import { Card } from "primereact/card";


function ArretsDiff(props) {
  const params = new URLSearchParams(window.location.search)
  const urlEmail = params.get('email')
  const toast = React.useRef(null);
  const dt = React.useRef(null);
  // definition du state
  let [state, setState] = React.useState({
    tabArrets: [],
    email: `${!!props.email ? props.email : `${!!urlEmail ? urlEmail : " "}`}`,
    refresh: true,
    confirmVisible: false,
    checkbox: false,
    globalFilter: null
  })

  React.useEffect(() => {
    setState({
      ...state,
      refresh: true
    })
  }, [state.checkbox])

  const recupArretsDiff = async () => {
    const data = await getArretDiff(`${!state.checkbox ? state.email : ""}`)
    setState({
      ...state,
      tabArrets: data,
      refresh: false
    })
  }

  React.useEffect(() => {
    recupArretsDiff()
  }, [state.refresh])

  const annul = async (data) => {
    const del = await delArretDiff(data)
    console.log('del:', del)

    const endSchedule = await cancelSubsEndPeriod({
      subs: props.subs,
      action: 'release'
    })
    console.log('endSchedule:', endSchedule)

    setState({
      ...state,
      refresh: true
    })

    toast.current.show({ severity: 'info', summary: 'Confirmé', detail: 'Arrêt différé supprimé', life: 3000 });
  }
  const reject = () => {
    toast.current.show({ severity: 'info', summary: 'Annuler', detail: 'Annulation de la suppression', life: 3000 });
  }

  const confirm = (data) => {
    console.log('data:', data)
    confirmPopup({
      target: data.event.currentTarget,
      message: `Veux tu vraiment supprimer l'arrêt différé de ${data.firstname} ${data.lastname}?`,
      icon: 'pi pi-exclamation-triangle',
      acceptLabel: "OUI",
      rejectLabel: "NON",
      accept: () => annul(data),
      reject: () => reject()
    });
  }

  const header = (
    <div className="">
      <h1>Arrêts Différés Programmés</h1>
      <div>
        <div>
          <InputText type="search" onChange={(e) => setState({ ...state, globalFilter: e.target.value })} placeholder="Recherche..." />
        </div>
        <div>
          <Checkbox inputId="checkbox" checked={state.checkbox} onChange={(e) => setState({
            ...state,
            checkbox: e.checked
          }
          )} />
          <label className="m-2" htmlFor="checkbox">Afficher tous les arrêts différés</label>
        </div>
      </div>

    </div>
  );

  const button = (items) => {
    return <Button
      onClick={
        (e) => confirm({
          id: items.id,
          date: items.date,
          firstname: items.infos.customer.firstname,
          lastname: items.infos.customer.lastname,
          event: e
        })
      }
    > Annuler</Button >
  }

  return (

    <Card className="m-1 p-0">
      <Toast ref={toast} />

      <DataTable
        header={header}
        resizableColumns
        value={state.tabArrets}
        globalFilter={state.globalFilter}
      >
        <Column field="date" header="date" style={{ 'width': '110px' }} />
        <Column field="infos.email" header="email" style={{ 'width': '300px' }} />
        <Column field="infos.customer.product_id.name" header="Formule" style={{ 'width': '150px' }} />

        <Column body={button} ></Column>
      </DataTable>
    </Card>

  );
}

export default ArretsDiff;
