
export default function verifMail(mail) {

    //regex
    const space = new RegExp(/[ ]/gm)
    const regex = new RegExp(/[\w-\.]+@([\w-]+\.)+(net|fr|com|org|ad|am|be|ca|ch|ci|de|gy|il|it|lu|mc|mq|mu|pm|tf|eu|email|edu|me)$/)

    let count = 0;
    let result = false;

    if (space.test(mail)) {
        //console.log("space mail HS")
    } else {
        //console.log("space mail OK")
        count = count + 1
    }

    if (regex.test(mail)) {
        //console.log("regex mail OK")
        count = count + 1
    } else {
        //console.log("regex mail HS")
    }

    //si passé tout les test
    if (count === 2) {
        result = true
    }
    //console.log("result mail : ", result)
    return result
}
