import React, { useContext } from 'react'
import { UserContext } from "./context/userContext"
import Public from "./pages/Public";
import Private from "./pages/Private"

function App() {

  const { currentUser } = useContext(UserContext)

  if (!currentUser) {
    return <Public />
  }

  return (
    <div className="container">
      <Private />
    </div>
  )
}

export default App;
