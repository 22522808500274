import './App.css';

import React, { useContext } from 'react'
import { UserContext } from "../../context/userContext"
import { Menubar } from 'primereact/menubar';
import { Message } from 'primereact/message';
import { Button } from 'primereact/button';
import ChampsRechercheMail from '../../components/ChampsRechercheMail';
import VerifRecup from '../../components/VerifRecup';

import Rembourssements from './SubPages/Rembourssements';
import ArretDiff from './SubPages/ArretsDiff';
import ModifCustomer from './SubPages/ModifCustomer'
import AddBackUser from '../../components/AddBackUser';

import EspacesClient from '../../components/EspacesClient';
import Customer from '../../components/Customer';
import axios from 'axios';


function App(props) {
  const { signOut, currentUser } = useContext(UserContext);

  const recupInfosCloudFlare = async () => {
    //recup des infos
    const cloudFlare = await axios.get('https://www.cloudflare.com/cdn-cgi/trace')
    //séparation des infos du text de sortie
    const dataCloudFlare = cloudFlare.data.split("\n")
    //transformation en objet
    let objCloudFlare
    for (let obj in dataCloudFlare) {
      const split = dataCloudFlare[obj].split("=")
      objCloudFlare = {
        ...objCloudFlare,
        [`${split[0]}`]: `${split[1]}`
      }
    }
    console.log("IpV6 client : ", objCloudFlare.ip)
    return objCloudFlare
  }
  recupInfosCloudFlare()

  const params = new URLSearchParams(window.location.search)
  let emailUrl = params.get('email')
  let admin


  // definition du state
  // refresh : variable utilisée pour relancer la recherche de ZoneSubscripton et ZonePaymentIntent
  let [state, setState] = React.useState({
    email: emailUrl,
    refresh: false,
    subs: null,
    customer: null,
    activeCampaignId: null,
    rowReporting: null,
    rowTestNewVip: null,
    rowAcceptations: null,
    search: null,
    message: null
  })
  let [temp, settemp] = React.useState(null)

  //fonction de mise a jour du mail utilisé par ChampsRechercheMail
  const callbackFunction = (childData) => {
    settemp({
      ...childData
    })
  };

  React.useEffect(() => {
    setState({
      ...state,
      ...temp
    })
  }, [temp])

  //erreur dans les datas
  let [errorData, setErrorData] = React.useState(false)


  //fonction de mise a jour 
  const callbackReload = () => {
    setErrorData(false)
    setState({
      email: emailUrl,
      subs: null,
      customer: null,
      activeCampaignId: null,
      rowReporting: null,
      rowTestNewVip: null,
      rowAcceptations: null,
      search: null,
      message: null
    })
  }

  //gestion du menu
  const end = () => {
    return (
      <div className='flex align-items-center'>
        <ChampsRechercheMail email={!!state.email ? state.email : ""} callbackFunction={callbackFunction} state={state} callbackReload={callbackReload} admin={admin} />
        <Button className="p-button-danger p-button-rounded" icon='pi pi-sign-out' onClick={signOut} />
      </div>
    )
  }

  const items = [
    {
      label: "Rembourssements",
      icon: 'pi pi-wallet',
      command: () => {
        setMenu({
          menuSelect: 1
        })
      }
    },
    {
      label: "Arrets Différés",
      icon: 'pi pi-times-circle',
      command: () => {
        setMenu({
          menuSelect: 2
        })
      }
    }
  ]
  const [menu, setMenu] = React.useState({
    menuSelect: 1
  })

  //admin ou non???

  const userCellule = currentUser.attributes ? currentUser.attributes["custom:cellule"] : currentUser.challengeParam.userAttributes["custom:cellule"];
  if (params.get('admin') === "true" || userCellule === "IT") {
    admin = true
    items.push({
      label: "Modif Client",
      icon: 'pi pi-user-edit',
      command: () => {
        setMenu({
          menuSelect: 3
        })
      }
    })
    items.push({
      label: "Creation Back Office User",
      icon: 'pi pi-user-plus',
      command: () => {
        setMenu({
          menuSelect: 4
        })
      }
    })
  } else {
    admin = false
  }

  return (
    <div className='App bg-bluegray-900 flex flex-column justify-content-start h-screen' >
      <Menubar model={items} end={end} />

      {
        (state.customer && menu.menuSelect === 1) &&
        <div className='block md:flex'>

          <Customer customer={state.customer} />
          {
            state.search === null &&
            <EspacesClient email={state.email} customer={state.customer} rowReporting={state.rowReporting} rowTestNewVip={state.rowTestNewVip} rowAcceptations={state.rowAcceptations} sale={state.customer.sale} />
          }

        </div>
      }


      {
        menu.menuSelect === 1 &&
        <div className='align-items-center justify-content-center'>
          <VerifRecup state={state} setErrorData={setErrorData} />
        </div>
      }

      {
        errorData === true && !admin
          ?
          <Message className="text-sm m-0 m-2 p-2" severity="warn" text="erreur dans les données client, contactez le support" />
          :
          <React.Fragment>
            {
              state.search === null &&
              <React.Fragment>
                {
                  menu.menuSelect === 1 &&
                  <Rembourssements state={state} callbackReload={callbackReload} />
                }
              </React.Fragment>
            }
          </React.Fragment>
      }
      {
        menu.menuSelect === 2 &&
        <ArretDiff email={props.email} subs={state.subs} />
      }
      {
        menu.menuSelect === 3 &&
        <ModifCustomer customer={state.customer} />
      }
      {
        menu.menuSelect === 4 &&
        <AddBackUser />
      }

    </div >
  );
}

export default App;
